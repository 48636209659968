export default {
  avatar: null,
  user: { roles: [], carreras: [] },
  users: [],
  errorArray: [],
  fields: {},
  record: {
    nuevo: false,
    actualizado: false,
  },
  pagination: {},
  roles: [],
};
