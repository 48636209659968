import TipoCorrelativa from "@/apis/TipoCorrelativa";

const actions = {
  showDialog({ commit }) {
    commit("showDialog");
  },

  hideDialog({ commit }) {
    commit("hideDialog");
  },

  editTipoCorrelativa({ commit }, model) {
    commit("setModel", model);
  },

  async addTipoCorrelativa({ commit }, item) {
    try {
      const { status, data } = await TipoCorrelativa.store(item);
      if (status === 201) {
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteTipoCorrelativa({ commit }, id) {
    try {
      const { status } = await TipoCorrelativa.delete(id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateTipoCorrelativa({ commit }, model) {
    try {
      const { status, data } = await TipoCorrelativa.update(model);
      if (status === 200) {
        console.log(data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getTipoCorrelativas({ commit }) {
    try {
      const { status, data } = await TipoCorrelativa.all();
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await TipoCorrelativa.model();
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};

export default actions;
