import Turno from "@/apis/Turno";

const actions = {
  async getTurnosVigentes({ commit }) {
    try {
      const { status, data } = await Turno.vigentes();
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  showDialog({ commit }) {
    commit("showDialog");
  },

  hideDialog({ commit }) {
    commit("hideDialog");
  },

  editTurno({ commit }, model) {
    commit("setModel", model);
  },

  async addTurno({ commit }, item) {
    try {
      const { status, data } = await Turno.store(item);
      if (status === 201) {
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteTurno({ commit }, id) {
    try {
      const { status } = await Turno.delete(id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateTurno({ commit }, model) {
    try {
      const { status, data } = await Turno.update(model);
      if (status === 200) {
        console.log(data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getTurno({ commit }, id) {
    try {
      const { status, data } = await Turno.view(id);
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  setTurno({ commit }, model) {
    commit("setModel", model ? model : {});
  },

  async getTurnos({ commit }) {
    try {
      const { status, data } = await Turno.all();
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await Turno.model();
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};

export default actions;
