// import moment from "moment";
const getters = {
  errors(state) {
    var merrors = {};
    for (var i in state.errorArray) {
      merrors[state.errorArray[i].field] = state.errorArray[i].message;
    }
    return merrors;
  },
  porAnios(state) {
    return state.examens.sort((a, b) =>
      a.materia.anio > b.materia.anio ? 1 : -1
    );
  },
  // fechaSola(state){
  //   return moment(state.fecha).format('DD/MM/YYYY')
  // },
  // hora(state){
  //   return moment(state.fecha).format('HH:mm');
  // },
};

export default getters;
