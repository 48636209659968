import UCurricularCarrera from "@/apis/UCurricularCarrera";

const actions = {
  showDialog({ commit }) {
    commit("showDialog");
  },

  hideDialog({ commit }) {
    commit("hideDialog");
  },

  editUCurricularCarrera({ commit }, model) {
    commit("setModel", model);
  },

  async addUCurricularCarrera({ commit }, item) {
    try {
      const { status, data } = await UCurricularCarrera.store(item);
      if (status === 201) {
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteUCurricularCarrera({ commit }, id) {
    try {
      const { status } = await UCurricularCarrera.delete(id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateUCurricularCarrera({ commit }, model) {
    try {
      const { status, data } = await UCurricularCarrera.update(model);
      if (status === 200) {
        console.log(data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getAllUCurricularCarreras({ commit }, bundle) {
    try {
      const { status, data, headers } = await UCurricularCarrera.all(bundle);
      if (status === 200) {
        commit("setModels", { models: data, headers: headers });
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getUCurricularCarreras({ commit }, carrera_id) {
    try {
      const { status, data } = await UCurricularCarrera.porCarrera(carrera_id);
      if (status === 200) {
        commit("setPorCarrera", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async getUCurricularCarrera({ commit }, id) {
    try {
      const { status, data } = await UCurricularCarrera.view(id);
      if (status === 200) {
        commit("setModel", data);
        return data;
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async setUCurricularCarrera({ commit }, model) {
    commit("setModel", model);
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await UCurricularCarrera.model();
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};

export default actions;
