export default {
  alumnoCarrera: { carrera: {} },
  alumnoCarreras: [],
  fields: {},
  errorArray: [],
  dialog: false,
  pagination: {},
  nuevo: false,
  actualizado: false,
  documentacion: {
    dni_frente: {},
    dni_dorso: {},
    cuil: {},
    formulario_inscripcion: {},
    formulario_medico: {},
  },
};
