import Vue from "vue";
import { httpClient } from "@/apis/Api";

const END_POINT = "nota-alumno-cursados";

export default {
  getAprobados(bundle) {
    const params = {
      options: bundle.options,
      filters: bundle.filters,
      like: bundle.like,
      // ...bundle.filters,
      expand: bundle.expand,
      search: bundle.search,
      headers: bundle.headers,
    };
    return httpClient.get(END_POINT + "/custom/getaprobados", { params });
  },

  updateFicha(data) {
    return httpClient.post(END_POINT + "/custom/updateficha", data);
  },

  deleteFicha(id) {
    return httpClient.post(END_POINT + "/custom/deleteficha", { id: id });
  },

  notificarme(id) {
    return httpClient.post(END_POINT + "/custom/notificarme", { id: id });
  },

  guardarFicha(data) {
    return httpClient.post(END_POINT + "/custom/guardarficha", data);
  },

  customQuery() {
    const params = {};
    return Vue.prototype.$axios.get(END_POINT + "/custom/query", { params });
  },
};
