export default {
  cursado: { espacio: {}, docentes: [], horariosFormateados: {} },
  cursados: [],
  errorArray: [],
  fields: {},
  record: {
    nuevo: false,
    actualizado: false,
  },
  pagination: {},
};
