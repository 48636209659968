import Vue from "vue";
import VueRouter from "vue-router";
import AuthLayout from "@/layouts/AuthLayout";
// import LoginRegister from "@/views/LoginRegister";
import authService from "@/services/auth.service";

import NotasCursados from "@/flavor/docente/views/Cursada/NotasCursados";
import NotasExamens from "@/flavor/docente/views/Examenes/NotasExamens";
import LoginDocente from "@/flavor/docente/views/LoginDocente";
import PerfilDocente from "@/flavor/docente/views/PerfilDocente";
import Logout from "@/views/user/Logout";
import RecuperarPassword from "@/views/user/RecuperarPassword";
import RegistrarDocente from "@/flavor/docente/views/RegistrarDocente";
import DocenteMateriaList from "@/flavor/docente/views/materias/DocenteMateriaList";
import SideBarDocenteLayout from "@/layouts/SideBarDocenteLayout";
import AlumnosCursadaListDocente from "@/flavor/docente/views/alumnos-cursada/AlumnosCursadaListDocente";
import DocenteCursadosList from "@/flavor/docente/views/comisiones/DocenteCursadosList";
import DocenteExamenesList from "@/flavor/docente/views/examenes-finales/DocenteExamenesList";
import AlumnosExamenListDocente from "@/flavor/docente/views/alumnos-examen/AlumnosExamenListDocente";
import PlanillaDigitalCursado from "@/flavor/docente/views/planilla-cursado/PlanillaDigitalCursado";
import PlanillaDigitalExamen from "@/flavor/docente/views/planilla-examen/PlanillaDigitalExamen";
import Profile from "@/viewsNew/user/Profile.vue";
import ProgramaExamenAlumno from "@/flavor/alumno/views/programa-examenes/ProgramaExamenAlumno.vue";
import CoordinacionExamenABM from "@/flavor/docente/views/coordinacion/examen/CoordinacionExamenABM.vue";
import CoordinacionCursadoABM from "@/flavor/docente/views/coordinacion/cursado/CoordinacionCursadoABM.vue";
import DocenteProgramaExamenABM from "@/flavor/docente/views/programa-examen/DocenteProgramaExamenABM.vue";
import ProgramaExamenABM from "@/viewsNew/programa-examen/ProgramaExamenABM.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/",
    name: "Default",
    component: SideBarDocenteLayout,
    redirect: "/docente/cursados",
    children: [
      {
        path: "/docente/todos-programas",
        name: "todos-programas",
        component: ProgramaExamenABM,
        props: true,
      },
      {
        path: "/docente/mis-programas",
        name: "mis-programas",
        component: DocenteProgramaExamenABM,
        props: true,
      },
      {
        path: "/coordinacion-cursado",
        name: "coordinacion-cursado",
        component: CoordinacionCursadoABM,
        props: true,
      },
      {
        path: "/coordinacion-examen",
        name: "coordinacion-examen",
        component: CoordinacionExamenABM,
        props: true,
      },
      {
        path: "/docente/programa-examenes",
        name: "programa-examenes",
        component: ProgramaExamenAlumno,
        props: true,
      },
      {
        path: "/docente/profile",
        name: "profile",
        component: Profile,
        props: true,
      },
      {
        path: "/planilla-digital-cursado/:cursado_id",
        name: "planillaDigital",
        component: PlanillaDigitalCursado,
        props: true,
      },
      {
        path: "/planilla-digital-examen/:examen_id",
        name: "planillaDigitalExamen",
        component: PlanillaDigitalExamen,
        props: true,
      },

      {
        path: "/notas-cursada/:cursada_id",
        name: "notasCursada",
        component: NotasCursados,
        props: true,
      },
      {
        path: "/docente/espacios",
        name: "docente-espacios",
        component: DocenteMateriaList,
        props: true,
      },
      {
        path: "/notas-examen/:examen_id",
        name: "notasExamen",
        component: NotasExamens,
        props: true,
      },
      {
        path: "/docente/perfil",
        name: "perfil-docente",
        component: PerfilDocente,
        props: true,
      },

      {
        path: "/docente/cursados",
        name: "docente-cursado",
        component: DocenteCursadosList,
        props: true,
      },
      {
        path: "/docente/alumno-cursado/:cursado_id",
        name: "alumno-cursado-docente",
        component: AlumnosCursadaListDocente,
        props: true,
      },
      {
        path: "/docente/alumno-examen/:examen_id",
        name: "alumno-cursado-examen",
        component: AlumnosExamenListDocente,
        props: true,
      },
      {
        path: "/docente/examenes",
        name: "examenes-docente",
        component: DocenteExamenesList,
        props: true,
      },
    ],
  },

  {
    path: "/auth",
    name: "Auth",
    component: AuthLayout,
    children: [
      {
        path: "recuperar",
        name: "recuperar",
        component: RecuperarPassword,
      },
      {
        path: "login",
        name: "login",
        component: LoginDocente,
      },
      {
        path: "register",
        name: "register",
        component: RegistrarDocente,
      },
    ],
  },
  {
    path: "/login",
    redirect: "/auth/login",
  },
  {
    path: "/register",
    redirect: "/auth/register",
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "recuperar" || to.name === "register") {
    next();
  } else if (to.name !== "login" && !authService.isLoggedIn())
    next({ name: "login" });
  else {
    next();
  }
});

const DEFAULT_TITLE = "Docentes | UNES";
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
