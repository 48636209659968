import Vue from "vue";

const END_POINT = "programa-examen";

export default {
  customQuery() {
    const params = {};
    return Vue.prototype.$axios.get(END_POINT + "/custom/query", { params });
  },

  downloadPdf(id) {
    const params = { id: id };
    return Vue.prototype.$axios.get(END_POINT + "/custom/download", { params });
  },
};
