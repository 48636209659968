<template>
  <tr>
    <td></td>
    <td v-for="(filterCol, i) in headers" :key="i">
      <slot
        :name="filterCol.value"
        :filterCol="filterCol"
        :like="like"
        :filters="filters"
      >
        <v-text-field
          dense
          outlined
          v-show="filterCol.filterable"
          hide-details
          filled
          :label="'Buscar por ' + filterCol.text"
          v-model="like[filterCol.table ? filterCol.table : filterCol.value]"
          clearable
          @click:clear="reload"
        ></v-text-field>
      </slot>
    </td>
  </tr>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "FilterRow",
  props: {
    headers: {
      type: Array,
    },
  },
  computed: {},
  watch: {
    like: {
      handler() {
        clearTimeout(this.timeout);
        var self = this;
        this.timeout = setTimeout(function () {
          self.setFilters();
          self.reload();
        }, 500);
      },
      deep: true,
    },
  },
  data() {
    return {
      timeout: null,
      like: { carrera: {}, materia: {} },
      filters: {},
    };
  },
  methods: {
    ...mapMutations(["setFilters"]),
    reload() {
      this.$emit("reload");
    },
  },
};
</script>

<style scoped></style>
