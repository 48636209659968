const getters = {
  errors(state) {
    var merrors = {};
    for (var i in state.errorArray) {
      merrors[state.errorArray[i].field] = state.errorArray[i].message;
    }
    return merrors;
  },
  codigos(state) {
    return state.carreras.map((el) => el.codigo);
  },
};

export default getters;
