"use strict";

import Vue from "vue";
import axios from "axios";
import authService from "@/services/auth.service";
import { Message } from "element-ui";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.Authorization = `Bearer ${authService.getToken()}`;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let loader = {};
let loading = false;

function showLoader() {
  let loader = Vue.$loading.show({
    color: "#017BFF",
    loader: "dots",
    width: 128,
    height: 128,
    backgroundColor: "#ffffff",
    opacity: 0.5,
    zIndex: 999,
    canCancel: true,
    // onCancel: hideLoader(),
  });
  return loader;
}

const API_ENDPOINT =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_HOST_DESARROLLO
    : process.env.VUE_APP_HOST_PRODUCCION;

let config = {
  baseURL: `${API_ENDPOINT}/v1/api/`,
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.Authorization = `Bearer ${authService.getToken()}`;
    if (!loading) {
      loader = showLoader();
      loading = true;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data

    if (response.status === 201) {
      Message({
        type: "success",
        message: "registro creado correctamente",
      });
    }
    if (response.status === 204) {
      Message({
        type: "warning",
        message: "registro eliminado correctamente",
      });
    }

    loader.hide();
    loading = false;
    return response;
  },
  function (error) {
    // Do something with response error

    if (error.response.status === 401) {
      window.location.href = "/login";
    } else {
      Vue.prototype.$dialog.error({
        text: error.response.data ? error.response.data : error,
        title: "Error",
      });
    }
    loader.hide();
    loading = false;
    // console.log(error.response.data.message);
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
