export default {
    turno: {},
    turnos:[],
    errorArray:[],
    fields:{},
    record: {
        nuevo: false,
        actualizado: false,
    },
    pagination:{},
}
