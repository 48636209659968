import Cursado from "@/apis/Cursado";

const actions = {
  async deleteAll({ commit }, items) {
    try {
      await Cursado.deleteAll(items);
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getCiclosLectivos({ commit }) {
    try {
      const { status, data } = await Cursado.getCiclosLectivos();
      if (status === 200) {
        commit("setCiclos", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async vigentesPorAlumno({ commit }, options) {
    try {
      const { status, data, headers } = await Cursado.vigentesPorAlumno(
        options
      );
      if (status === 200) {
        commit("setModels", { models: data, headers: headers });
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async porAlumno({ commit }) {
    try {
      const { status, data, headers } = await Cursado.porAlumno();
      if (status === 200) {
        commit("setModels", { models: data, headers: headers });
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async porDocente({ commit }, bundle) {
    try {
      const { status, data, headers } = await Cursado.porDocente(bundle);
      if (status === 200) {
        commit("setModels", { models: data, headers: headers });
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  showDialog({ commit }) {
    commit("showDialog");
  },

  hideDialog({ commit }) {
    commit("hideDialog");
  },

  editCursado({ commit }, model) {
    commit("setModel", model);
  },

  async addCursado({ commit }, item) {
    try {
      const { status, data } = await Cursado.store(item);
      if (status === 201) {
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async crearCicloLectivo({ commit }, form) {
    console.log(form);
    try {
      const { status, data } = await Cursado.crearCicloLectivo(form);
      if (status === 201) {
        commit("addModels", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteCursado({ commit }, id) {
    try {
      const { status } = await Cursado.delete(id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateCursado({ commit }, model) {
    try {
      const { status, data } = await Cursado.update(model);
      if (status === 200) {
        console.log(data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getCursados({ commit }, bundle) {
    try {
      const { status, data, headers } = await Cursado.all(bundle);
      // console.log(headers);
      if (status === 200) {
        commit("setModels", { models: data, headers: headers });
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async getCursado({ commit }, cursado) {
    try {
      const { status, data } = await Cursado.view(cursado);
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await Cursado.model();
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};

export default actions;
