<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="avatar-div">
          <v-avatar
            size="150"
            @mouseover="showEditButton"
            @mouseleave="hideEditButton"
            class="avatar-container"
          >
            <div class="edit-icon">
              <input
                model="user.avatar"
                ref="fileInput"
                type="file"
                style="display: none"
                accept="image/*"
                @change="subirImagen"
              />
              <v-btn
                v-if="showButton"
                @click="openImageEditor"
                icon
                color="error"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
            <img :src="avatar" alt="Avatar" class="mobile-avatar" />
          </v-avatar>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col xs="12" md="6">
        <v-text-field
          disabled
          outlined
          label="Nombre"
          v-model="user.nombre"
          readonly
        ></v-text-field>
      </v-col>

      <v-col xs="12" md="6">
        <v-text-field
          disabled
          outlined
          label="Apellido"
          v-model="user.apellido"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col xs="12" md="6">
        <v-text-field
          outlined
          label="Email"
          v-model="user.email"
        ></v-text-field>
      </v-col>

      <v-col xs="12" md="6">
        <v-text-field
          disabled
          outlined
          label="DNI"
          v-model="user.dni"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col xs="12" md="6">
        <v-text-field
          outlined
          label="Teléfono"
          v-model="user.telefono"
        ></v-text-field>
      </v-col>

      <!--      <v-col xs="12" md="6">-->
      <!--        <v-text-field outlined label="Contraseña" type="password" v-model="user.password" ></v-text-field>-->
      <!--      </v-col>-->
    </v-row>
    <v-row>
      <v-col xs="12" md="6">
        <v-btn class="save-button" @click="saveUser" color="primary"
          >Guardar</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      // user: {
      //   avatar: 'https://placekitten.com/150/150',
      //   name: 'John',
      //   lastName: 'Doe',
      //   email: 'john.doe@example.com',
      //   dni: '12345678',
      //   phone: '123-456-7890',
      //   password: '********',
      // },
      showButton: false,
    };
  },
  computed: {
    ...mapState("UserModule", ["user", "avatar"]),
  },
  methods: {
    ...mapActions("UserModule", ["updateUser", "uploadImagen", "getAvatar"]),
    saveUser() {
      console.log("guardar datos usuario");
      this.updateUser(this.user);
    },
    async subirImagen(event) {
      const selectedFile = event.target.files[0];
      console.log("subir imagen se esta procesando");
      //this.setErrors({});
      const formData = new FormData();
      // for(var i  in this.imageFile){
      formData.append("imageFile", selectedFile, selectedFile.name);
      // }
      formData.append("user", JSON.stringify(this.user));
      // formData.append('imageFile', this.imageFile.file, this.imageFile.name)
      // this.imagen.FK_ID_PROPIEDAD = this.propiedad.ID_PROPIEDAD;
      await this.uploadImagen(formData);
      this.getAvatar(this.user);
    },

    showEditButton() {
      this.showButton = true;
    },
    hideEditButton() {
      this.showButton = false;
    },
    openImageEditor() {
      // Lógica para abrir el editor de imágenes (puedes implementar esta función según tus necesidades)
      // Por ejemplo, puedes usar un modal o redirigir a otra página para la edición de imágenes.
      console.log("Abrir editor de imágenes");
      this.$refs.fileInput.click();
    },
  },
  created() {
    this.getAvatar(this.user);
  },
};
</script>

<style scoped>
.avatar-container {
  position: relative;
}

.edit-icon {
  z-index: 500;
  position: absolute;
  bottom: 0px;
  right: 60px;
  opacity: 1; /* Por defecto, ocultamos el botón de edición */
  transition: opacity 0.3s ease; /* Agregamos una transición suave de opacidad */
}

.avatar-container:hover .edit-icon {
  opacity: 1; /* Mostramos el botón de edición cuando el contenedor es hover */
}
/* Estilos específicos para el componente */

/* Estilos para dispositivos móviles */
@media only screen and (max-width: 600px) {
  .save-button {
    width: 100%;
  }

  .avatar-div {
    display: flex;
    justify-content: center;
  }
  .row {
    flex-direction: column;
    align-items: center;
  }

  .mobile-avatar {
    margin-bottom: 20px;
  }
  .col {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
