var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_vm._t("dialog",function(){return [_c('my-modal',{attrs:{"eager":false,"dialog":_vm.dialog},on:{"close":_vm.close},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"footer-btn",fn:function(){return [_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onSubmit}},[_vm._v(" Guardar ")])]},proxy:true}],null,true)},[_c('template',{slot:"header"},[_c('h3',[_vm._v(" "+_vm._s(_vm.alumnoCursado.alumno.apellido)+","+_vm._s(_vm.alumnoCursado.alumno.nombre)+" ")])]),_c('template',{slot:"body"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},[_vm._t("other-form",function(){return [_c('alumnoCursado-form',{attrs:{"only":_vm.only},scopedSlots:_vm._u([{key:"fecha_abandono",fn:function(){return [_c('div',[(_vm.abandono)?_c('vc-date-picker',{attrs:{"model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var togglePopover = ref.togglePopover;
return [_c('v-text-field',{attrs:{"clearable":"","label":"Seleccione Fecha de abandono","error-messages":_vm.errors.fecha_abandono,"value":inputValue},on:{"click":togglePopover,"click:clear":function($event){_vm.alumnoCursado.fecha_abandono = null}}})]}}],null,false,2204219694),model:{value:(_vm.alumnoCursado.fecha_abandono),callback:function ($$v) {_vm.$set(_vm.alumnoCursado, "fecha_abandono", $$v)},expression:"alumnoCursado.fecha_abandono"}}):_vm._e()],1)]},proxy:true},{key:"observaciones",fn:function(){return [_c('v-textarea',{attrs:{"label":"Ingrese Las Observaciones"},model:{value:(_vm.alumnoCursado.observaciones),callback:function ($$v) {_vm.$set(_vm.alumnoCursado, "observaciones", $$v)},expression:"alumnoCursado.observaciones"}})]},proxy:true}])})]})],2)],1)],2)]}),_c('h3',[_vm._v("Alumnos de "+_vm._s(_vm.cursado.nombreMateria)+" "+_vm._s(_vm.cursado.anio))]),_c('div',{staticClass:"p-3"},[_c('v-row',[_c('div',{staticClass:"mr-3"},[_c('alumno-cursado-p-d-f',{ref:"pdf",attrs:{"cursado":_vm.cursado,"items":_vm.alumnoCursados}}),(_vm.$refs.pdf)?_c('div',[_c('v-btn',{attrs:{"outlined":"","color":"dark"},on:{"click":_vm.$refs.pdf.generateReport}},[_vm._v("Exportar")])],1):_vm._e()],1),_c('div',[_c('v-btn',{attrs:{"color":"warning"},on:{"click":_vm.enviarMail}},[_vm._v("Enviar Mail")])],1)])],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"p-2"},[_c('v-switch',{attrs:{"label":"Editar"},model:{value:(_vm.disableNotas),callback:function ($$v) {_vm.disableNotas=$$v},expression:"disableNotas"}})],1),_c('div',{staticClass:"p-2"},[_c('v-switch',{attrs:{"label":"Carga Multiple"},model:{value:(_vm.cargaMultiple),callback:function ($$v) {_vm.cargaMultiple=$$v},expression:"cargaMultiple"}})],1)]),(_vm.cargaMultiple)?_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"clearable":"","label":"TP 1","items":[
          { value: 0, text: 'Desaprobado' },
          { value: 1, text: 'Aprobado' } ]},model:{value:(_vm.batch.tp1),callback:function ($$v) {_vm.$set(_vm.batch, "tp1", $$v)},expression:"batch.tp1"}})],1),_c('v-col',[_c('v-select',{attrs:{"clearable":"","label":"TP 2","items":[
          { value: 0, text: 'Desaprobado' },
          { value: 1, text: 'Aprobado' } ]},model:{value:(_vm.batch.tp2),callback:function ($$v) {_vm.$set(_vm.batch, "tp2", $$v)},expression:"batch.tp2"}})],1),_c('v-col',[_c('v-select',{attrs:{"clearable":"","label":"TP 3","items":[
          { value: 0, text: 'Desaprobado' },
          { value: 1, text: 'Aprobado' } ]},model:{value:(_vm.batch.tp3),callback:function ($$v) {_vm.$set(_vm.batch, "tp3", $$v)},expression:"batch.tp3"}})],1),_c('v-col',[_c('v-select',{attrs:{"clearable":"","label":"TP 4","items":[
          { value: 0, text: 'Desaprobado' },
          { value: 1, text: 'Aprobado' } ]},model:{value:(_vm.batch.tp4),callback:function ($$v) {_vm.$set(_vm.batch, "tp4", $$v)},expression:"batch.tp4"}})],1),_c('v-col',[_c('v-btn',{on:{"click":function($event){return _vm.notasBatchUpdate()}}},[_vm._v("Guardar")])],1)],1):_vm._e(),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"options":_vm.options,"server-items-length":_vm.pagination.totalCount,"show-select":true,"items":_vm.alumnoCursados,"headers":_vm.headers},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"item.tp1",fn:function(ref){
        var item = ref.item;
return [_c('div',{on:{"click":_vm.alerta}},[_c('v-switch',{attrs:{"true-value":1,"false-value":0,"disabled":!_vm.disableNotas,"label":item.tp1 ? 'SI' : 'NO'},on:{"change":function($event){return _vm.updateAlumnoCursado(item)}},model:{value:(item.tp1),callback:function ($$v) {_vm.$set(item, "tp1", $$v)},expression:"item.tp1"}})],1)]}},{key:"item.tp2",fn:function(ref){
        var item = ref.item;
return [_c('div',{on:{"click":_vm.alerta}},[_c('v-switch',{attrs:{"true-value":1,"false-value":0,"disabled":!_vm.disableNotas,"label":item.tp2 ? 'SI' : 'NO'},on:{"change":function($event){return _vm.updateAlumnoCursado(item)}},model:{value:(item.tp2),callback:function ($$v) {_vm.$set(item, "tp2", $$v)},expression:"item.tp2"}})],1)]}},{key:"item.tp3",fn:function(ref){
        var item = ref.item;
return [_c('div',{on:{"click":_vm.alerta}},[_c('v-switch',{attrs:{"true-value":1,"false-value":0,"disabled":!_vm.disableNotas,"label":item.tp3 ? 'SI' : 'NO'},on:{"change":function($event){return _vm.updateAlumnoCursado(item)}},model:{value:(item.tp3),callback:function ($$v) {_vm.$set(item, "tp3", $$v)},expression:"item.tp3"}})],1)]}},{key:"item.tp4",fn:function(ref){
        var item = ref.item;
return [_c('div',{on:{"click":_vm.alerta}},[_c('v-switch',{attrs:{"true-value":1,"false-value":0,"disabled":!_vm.disableNotas,"label":item.tp4 ? 'SI' : 'NO'},on:{"change":function($event){return _vm.updateAlumnoCursado(item)}},model:{value:(item.tp4),callback:function ($$v) {_vm.$set(item, "tp4", $$v)},expression:"item.tp4"}})],1)]}},{key:"item.nota_alumno",fn:function(ref){
        var item = ref.item;
return [_c('div',{on:{"click":_vm.alerta}},[_c('v-text-field',{attrs:{"disabled":!_vm.disableNotas,"type":"number"},on:{"input":function($event){return _vm.updateAlumnoCursado(item)}},model:{value:(item.nota_alumno),callback:function ($$v) {_vm.$set(item, "nota_alumno", $$v)},expression:"item.nota_alumno"}})],1)]}},{key:"item.cursado_aprobado",fn:function(ref){
        var item = ref.item;
return [_c('v-switch',{attrs:{"true-value":1,"false-value":0,"disabled":true,"label":item.cursado_aprobado ? 'SI' : 'NO'},on:{"change":function($event){return _vm.updateAlumnoCursado(item)}},model:{value:(item.cursado_aprobado),callback:function ($$v) {_vm.$set(item, "cursado_aprobado", $$v)},expression:"item.cursado_aprobado"}})]}},{key:"item.fecha_abandono",fn:function(ref){
        var item = ref.item;
return [_c('v-row',[_c('v-btn',{attrs:{"color":item.fecha_abandono ? 'error' : 'light'},on:{"click":function($event){return _vm.editAbandono(item, true)}}},[_vm._v(_vm._s(item.fecha_abandono ? item.fecha_abandono : "registrar"))])],1)]}},{key:"item.observaciones",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.editAbandono(item, false)}}},[_vm._v(" mdi-pencil ")])]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }