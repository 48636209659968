import { httpClient } from "./Api";

const END_POINT = "u-curricular-carreras";

export default {
  porCarrera(carrera_id) {
    const params = {
      expand: ["carrera", "campo", "correlativas", "docentes"].toString(),
      carrera_id: carrera_id,
    };
    return httpClient.get(END_POINT + "/custom/porcarrera", { params });
  },

  all(bundle) {
    const params = {
      expand: ["carrera", "campo", "correlativas", "docentes"].toString(),
      carrera_id: bundle.carrera_id ? bundle.carrera_id : -1,
      search: bundle.search,
      itemsPerPage: bundle.options.itemsPerPage,
      page: bundle.options.page,
      options: bundle.options,
      filters: bundle.filters,
    };
    return httpClient.get(END_POINT, { params });
  },

  store(data) {
    return httpClient.post(END_POINT, data);
  },

  delete(id) {
    return httpClient.delete(`${END_POINT}/${id}`);
  },

  update(item) {
    return httpClient.put(`${END_POINT}/${item.id}`, item);
  },
  view(id) {
    const params = {
      expand: ["correlativas", "correlativasCursado"].toString(),
    };
    return httpClient.get(`${END_POINT}/${id}`, { params });
  },
  model() {
    return httpClient.get(`${END_POINT}/model`);
  },
};
