import { httpClient } from "./Api";

const END_POINT = "ingresantes";

export default {
  deleteAll(items) {
    return httpClient.delete(END_POINT + "/custom/deleteall", {
      data: { items: items },
    });
  },

  matricular(data) {
    return httpClient.post(END_POINT + "/custom/matricular", data);
  },
  all(bundle) {
    const params = {
      expand: ["carrera", ...bundle.expand].toString(),
      options: bundle.options,
      filters: bundle.filters,
    };
    return httpClient.get(END_POINT, { params });
  },

  getCarrera(bundle) {
    const params = {
      expand: ["carrera"].toString(),
      options: bundle.options,
      filters: { ...bundle.filters, tipo_inscripcion: "carrera" },
    };
    return httpClient.get(END_POINT, { params });
  },

  matriculados(flag) {
    const params = {
      expand: ["carrera"].toString(),
      matriculado: flag,
    };
    return httpClient.get(END_POINT, { params });
  },

  store(data) {
    return httpClient.post(END_POINT, data);
  },

  delete(id) {
    return httpClient.delete(`${END_POINT}/${id}`);
  },

  update(item) {
    return httpClient.put(`${END_POINT}/${item.id}`, item);
  },
  view(id) {
    const params = {
      expand: [].toString(),
    };
    return httpClient.get(`${END_POINT}/${id}`, { params });
  },
  model() {
    return httpClient.get(`${END_POINT}/model`);
  },
};
