import ActiveModel from "@/apis/ActiveModel";

const END_POINT = "new-horario-cursados";

const actions = {
  async getHorarioCursados({ commit }, bundle) {
    try {
      const { status, data } = await ActiveModel.all(END_POINT, bundle);
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async getHorarioCursado({ commit }, id) {
    try {
      const { status, data } = await ActiveModel.view(END_POINT, id);
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async addHorarioCursado({ commit }, item) {
    try {
      const { status, data } = await ActiveModel.store(END_POINT, item);
      if (status === 201) {
        console.log("registro creado");
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteHorarioCursado({ commit }, id) {
    try {
      const { status } = await ActiveModel.delete(END_POINT, id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateHorarioCursado({ commit }, model) {
    try {
      const { status, data } = await ActiveModel.update(END_POINT, model);
      if (status === 200) {
        commit("updateModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await ActiveModel.model(END_POINT);
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};
export default actions;
