<template>
  <div>
    <docente-materia-form ref="form" @close="cerrar"></docente-materia-form>

    <v-card>
      <v-card-title> Espacios Curriculares </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-btn @click="abrirForm">Nuevo Espacio</v-btn>
          </v-col>
          <v-col>
            <v-select
              clearable
              multiple
              outlined
              label="Filtrar por Carrera"
              :items="carreras"
              item-text="nombre"
              item-value="id"
              v-model="carrera_id"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row v-for="(porCarrera, codigo) in porCarreras" :key="codigo">
          <v-col>
            <div class="title teal text-light p-3">
              {{ porCarrera.carrera.nombre }}
            </div>
            <v-row v-for="(materias, anio) in porCarrera.anios" :key="anio">
              <v-col>
                <span class="title">Materias de {{ anio }}° Año</span>
                <v-data-iterator :items="materias">
                  <template v-slot:default="{ items }">
                    <v-row>
                      <v-col
                        v-for="item in items"
                        cols="12"
                        md="6"
                        lg="4"
                        :key="item.id"
                      >
                        <v-card dark :color="item.estado ? 'primary' : 'dark'">
                          <v-card-title>{{ item.espacio.nombre }}</v-card-title>
                          <v-card-text>
                            <v-alert color="success" v-if="item.estado"
                              >Sos docente de esta Materia</v-alert
                            >
                          </v-card-text>
                          <v-card-actions>
                            <v-btn block @click="borrarItem(item)" color="error"
                              >Eliminar</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DocenteMateriaForm from "@/flavor/docente/views/materias/DocenteMateriaForm";

export default {
  name: "DocenteMateriaList",
  components: { DocenteMateriaForm },
  data() {
    return {
      carrera_id: null,
    };
  },
  computed: {
    // ...mapGetters("NewDocenteEspacio",["porCarreras"]),
    ...mapState("NewDocenteEspacio", ["docenteEspacios"]),
    ...mapState("UserModule", ["user"]),
    ...mapState("CarreraModule", ["carreras"]),
    porCarreras() {
      const carreras = this.docenteEspacios.reduce((carreras, item) => {
        console.log(item);
        let tag = item.espacio.anio;
        let tag2 = item.carrera.codigo;

        carreras[tag2] = carreras[tag2] || {};
        carreras[tag2].carrera = item.carrera;
        carreras[tag2].anios = carreras[tag2].anios || {};

        carreras[tag2].anios[tag] = carreras[tag2].anios[tag] || [];

        carreras[tag2].anios[tag].push(item);
        return carreras;
      }, {});
      return carreras;
    },
  },
  watch: {
    carrera_id() {
      this.reload();
    },
  },
  methods: {
    ...mapActions("NewDocenteEspacio", [
      "getDocenteEspacios",
      "deleteDocenteEspacio",
    ]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    async borrarItem(item) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteDocenteEspacio(item.id);
      }
    },
    reload() {
      this.getDocenteEspacios({
        options: {
          joins: ["carrera"],
          sortBy: ["id"],
          sortDesc: [false],
        },
        filters: { docente_id: this.user.id, carrera_id: this.carrera_id },
        expand: ["espacio", "carrera"].toString(),
      });
    },
    cerrar() {
      this.reload();
    },
    abrirForm() {
      this.$refs.form.dialog = true;
    },
    verAlumnos(item) {
      this.$router.push("/docente/alumno-cursado/" + item.id);
    },
  },
  created() {
    this.reload();
    this.getCarreras();
  },
};
</script>

<style scoped></style>
