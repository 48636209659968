import AlumnoCursado from "@/apis/AlumnoCursado";

const actions = {
  async sendMailNota({ commit }, items) {
    try {
      const { status, data } = await AlumnoCursado.sendMailNota(items);
      if (status === 200) {
        console.log(data);
        // commit("setModels", data);
        // commit('addModel',data)
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  showDialog({ commit }) {
    commit("showDialog");
  },

  hideDialog({ commit }) {
    commit("hideDialog");
  },

  editAlumnoCursado({ commit }, model) {
    commit("setModel", model);
  },

  async addAlumnoCursado({ commit }, item) {
    try {
      const { status, data } = await AlumnoCursado.store(item);
      if (status === 201) {
        commit("addModel", data);
        return data;
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async addBatch({ commit }, items) {
    try {
      await AlumnoCursado.addBatch(items);
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async batchUpdate({ commit }, items) {
    try {
      await AlumnoCursado.batchUpdate(items);
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteAlumnoCursado({ commit }, id) {
    try {
      const { status } = await AlumnoCursado.delete(id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async updateAlumnoCursado({ commit }, model) {
    try {
      model.cursado_aprobado = model.nota_alumno >= 6 ? 1 : 0;
      const { status, data } = await AlumnoCursado.update(model);
      if (status === 200) {
        console.log(data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getAlumnoCursadosPorCursado({ commit }, bundle) {
    try {
      const { status, data, headers } = await AlumnoCursado.porCurso(bundle);
      if (status === 200) {
        commit("setModels", { models: data, headers: headers });
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getAlumnoCursados({ commit }) {
    try {
      const { status, data } = await AlumnoCursado.all();
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await AlumnoCursado.model();
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};

export default actions;
