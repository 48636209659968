import { httpClient } from "./Api";

const END_POINT = "localidads";

export default {
  all(provincia_id) {
    const params = {
      expand: [].toString(),
      provincia_id: provincia_id,
    };
    return httpClient.get(END_POINT, { params });
  },

  view(id) {
    const params = {
      expand: [].toString(),
    };
    return httpClient.get(END_POINT + "/" + id, { params });
  },
};
