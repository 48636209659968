export default {
  corteInscripcionCarrera: {},
  corteInscripcionCarreras: [],
  errorArray: [],
  fields: {},
  record: {
    nuevo: false,
    actualizado: false,
  },
  pagination: {},
};
