import Vue from "vue";

export default {
  deleteAll(END_POINT, items) {
    return Vue.prototype.$axios.post(END_POINT + "/custom/deleteall", items);
  },

  all(END_POINT, bundle) {
    const params = {
      options: bundle.options,
      filters: bundle.filters,
      like: bundle.like,
      // ...bundle.filters,
      expand: bundle.expand,
      search: bundle.search,
      // headers: bundle.headers,
      // headers: bundle.headers.filter((el) => el.filterable),
    };
    return Vue.prototype.$axios.get(END_POINT, { params });
  },
  store(END_POINT, data) {
    return Vue.prototype.$axios.post(END_POINT, data);
  },

  delete(END_POINT, id) {
    return Vue.prototype.$axios.delete(`${END_POINT}/${id}`);
  },

  update(END_POINT, item) {
    return Vue.prototype.$axios.put(`${END_POINT}/${item.id}`, item);
  },
  view(END_POINT, id) {
    const params = {
      // expand:bundle.expand,
    };
    return Vue.prototype.$axios.get(`${END_POINT}/${id}`, { params });
  },
  viewWithParams(END_POINT, id, bundle) {
    const params = {
      expand: bundle.expand,
    };
    return Vue.prototype.$axios.get(`${END_POINT}/${id}`, { params });
  },

  model(END_POINT) {
    return Vue.prototype.$axios.get(`${END_POINT}/model`);
  },
};
