<template>
  <v-dialog eager v-model="dialog" scrollable>
    <v-card>
      <v-card-title> Espacios Curriculares </v-card-title>
      <v-card-text>
        <v-select
          label="Seleccione una Carrera"
          outlined
          v-model="carrera"
          :items="carreras"
          item-value="id"
          item-text="nombre"
        ></v-select>
        <div v-for="materia in materias" :key="materia.id">
          <v-checkbox
            @change="check($event, materia)"
            hide-details
            :label="materia.searchTag"
          ></v-checkbox>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="agregarMaterias">Agregar</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="cerrar">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DocenteMateriaForm",
  data() {
    return {
      carrera: null,
      selectedItems: [],
      dialog: false,
    };
  },
  computed: {
    ...mapState("UserModule", ["user"]),
    ...mapState("CarreraModule", ["carreras"]),
    ...mapState("NewUCurricularCarreraModule", ["uCurricularCarreras"]),
    ...mapState("NewDocenteEspacio", ["docenteEspacios"]),

    materias() {
      return this.uCurricularCarreras.filter(
        (el) => !this.docenteEspacios.map((el) => el.espacio_id).includes(el.id)
      );
    },
  },
  methods: {
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("NewUCurricularCarreraModule", ["getUCurricularCarreras"]),
    ...mapActions("NewDocenteEspacio", ["addDocenteEspacio"]),
    check(event, materia) {
      console.log(event);
      console.log(materia);
      if (event) {
        this.selectedItems.push(materia.id);
      } else {
        const index = this.selectedItems.findIndex((el) => el === materia.id);
        console.log(index);
        if (index > -1) {
          this.selectedItems.splice(index, 1);
          console.log(this.selectedItems);
        }
      }
    },
    async agregarMaterias() {
      for (var i in this.selectedItems) {
        var model = {
          docente_id: this.user.id,
          espacio_id: this.selectedItems[i],
        };
        await this.addDocenteEspacio(model);
      }
      this.cerrar();
    },
    cerrar() {
      this.dialog = false;
      this.$emit("close");
    },
  },
  watch: {
    carrera() {
      this.selectedItems = [];
      this.getUCurricularCarreras({
        filters: { carrera_id: this.carrera },
        options: {
          itemsPerPage: -1,
          sortBy: ["anio", "nombre"],
          sortDesc: [false, false],
        },
      });
    },
    dialog() {
      this.carrera = -1;
      this.selectedItems = [];
    },
  },
  created() {
    // this.getCarreras();
  },
};
</script>

<style scoped></style>
