<template>
  <v-container fluid>
    <slot name="dialog">
      <my-modal :eager="false" :dialog="dialog" @close="close">
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>

        <template slot="header"
          ><h3>
            {{ alumnoCursado.alumno.apellido }},{{
              alumnoCursado.alumno.nombre
            }}
          </h3></template
        >
        <template slot="body">
          <v-form @submit.prevent="onSubmit">
            <slot name="other-form">
              <alumnoCursado-form :only="only">
                <template v-slot:fecha_abandono>
                  <div>
                    <vc-date-picker
                      v-if="abandono"
                      class=""
                      v-model="alumnoCursado.fecha_abandono"
                      :model-config="modelConfig"
                    >
                      <template v-slot="{ inputValue, togglePopover }">
                        <v-text-field
                          clearable
                          label="Seleccione Fecha de abandono"
                          :error-messages="errors.fecha_abandono"
                          :value="inputValue"
                          @click="togglePopover"
                          @click:clear="alumnoCursado.fecha_abandono = null"
                        />
                      </template>
                    </vc-date-picker>
                  </div>
                </template>
                <template v-slot:observaciones>
                  <v-textarea
                    label="Ingrese Las Observaciones"
                    v-model="alumnoCursado.observaciones"
                  ></v-textarea>
                </template>
              </alumnoCursado-form>
            </slot>
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
              ><slot :name="slot" v-bind="scope"
            /></template>
          </v-form>
        </template>
        <template v-slot:footer-btn>
          <v-btn color="blue darken-1" text @click="onSubmit"> Guardar </v-btn>
        </template>
      </my-modal>
    </slot>
    <h3>Alumnos de {{ cursado.nombreMateria }} {{ cursado.anio }}</h3>

    <div class="p-3">
      <v-row>
        <div class="mr-3">
          <alumno-cursado-p-d-f
            ref="pdf"
            :cursado="cursado"
            :items="alumnoCursados"
          ></alumno-cursado-p-d-f>
          <div v-if="$refs.pdf">
            <v-btn outlined color="dark" @click="$refs.pdf.generateReport"
              >Exportar</v-btn
            >
          </div>
        </div>
        <div>
          <v-btn color="warning" @click="enviarMail">Enviar Mail</v-btn>
        </div>
      </v-row>
    </div>

    <div class="d-flex">
      <div class="p-2">
        <v-switch label="Editar" v-model="disableNotas" />
      </div>
      <div class="p-2">
        <v-switch label="Carga Multiple" v-model="cargaMultiple" />
      </div>
    </div>

    <v-row v-if="cargaMultiple">
      <v-col>
        <v-select
          clearable
          v-model="batch.tp1"
          label="TP 1"
          :items="[
            { value: 0, text: 'Desaprobado' },
            { value: 1, text: 'Aprobado' },
          ]"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          clearable
          v-model="batch.tp2"
          label="TP 2"
          :items="[
            { value: 0, text: 'Desaprobado' },
            { value: 1, text: 'Aprobado' },
          ]"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          clearable
          v-model="batch.tp3"
          label="TP 3"
          :items="[
            { value: 0, text: 'Desaprobado' },
            { value: 1, text: 'Aprobado' },
          ]"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          clearable
          v-model="batch.tp4"
          label="TP 4"
          :items="[
            { value: 0, text: 'Desaprobado' },
            { value: 1, text: 'Aprobado' },
          ]"
        ></v-select>
      </v-col>
      <!--      <v-col>-->
      <!--        <v-text-field type="number" v-model="batch.nota_alumno"></v-text-field>-->
      <!--      </v-col>-->

      <v-col>
        <v-btn @click="notasBatchUpdate()">Guardar</v-btn>
      </v-col>
    </v-row>
    <!--    <v-row no-gutters>-->
    <!--      <div>-->
    <!--        <v-btn @click="newItem" outlined color="primary">Nuevo</v-btn>-->
    <!--      </div>-->
    <!--      <import-export model-class="AlumnoCursados" :path="uploadPath"  :only="only" :model-fields="fields" :plantilla="[alumnoCursado]"></import-export>-->

    <!--    </v-row>-->

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :options.sync="options"
      :server-items-length="pagination.totalCount"
      v-model="selectedItems"
      :show-select="true"
      :items="alumnoCursados"
      :headers="headers"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>

      <template v-slot:item.tp1="{ item }">
        <div @click="alerta">
          <v-switch
            :true-value="1"
            :false-value="0"
            @change="updateAlumnoCursado(item)"
            :disabled="!disableNotas"
            :label="item.tp1 ? 'SI' : 'NO'"
            v-model="item.tp1"
          ></v-switch>
        </div>
        <!--        <v-switch :disabled="!disableNotas" label="A" v-model="item.tp1"></v-switch>-->
        <!--        <v-select-->
        <!--          @change="updateAlumnoCursado(item)"-->
        <!--          :disabled="!disableNotas"-->
        <!--          :items="[-->
        <!--            { value: 0, text: 'Desaprobado' },-->
        <!--            { value: 1, text: 'Aprobado' },-->
        <!--          ]"-->
        <!--          v-model="item.tp1"-->
        <!--          item-value="value"-->
        <!--          item-text="text"-->
        <!--        ></v-select>-->
      </template>
      <template v-slot:item.tp2="{ item }">
        <div @click="alerta">
          <v-switch
            :true-value="1"
            :false-value="0"
            @change="updateAlumnoCursado(item)"
            :disabled="!disableNotas"
            :label="item.tp2 ? 'SI' : 'NO'"
            v-model="item.tp2"
          ></v-switch>
        </div>
        <!--        <v-select-->
        <!--            clearable-->
        <!--          @change="updateAlumnoCursado(item)"-->
        <!--          :disabled="!disableNotas"-->
        <!--          :items="[-->
        <!--            { value: 0, text: 'Desaprobado' },-->
        <!--            { value: 1, text: 'Aprobado' },-->
        <!--          ]"-->
        <!--          v-model="item.tp2"-->
        <!--          item-value="value"-->
        <!--          item-text="text"-->
        <!--        ></v-select>-->
      </template>
      <template v-slot:item.tp3="{ item }">
        <div @click="alerta">
          <v-switch
            :true-value="1"
            :false-value="0"
            @change="updateAlumnoCursado(item)"
            :disabled="!disableNotas"
            :label="item.tp3 ? 'SI' : 'NO'"
            v-model="item.tp3"
          ></v-switch>
        </div>
        <!--        <v-select-->
        <!--          @change="updateAlumnoCursado(item)"-->
        <!--          :disabled="!disableNotas"-->
        <!--          :items="[-->
        <!--            { value: 0, text: 'Desaprobado' },-->
        <!--            { value: 1, text: 'Aprobado' },-->
        <!--          ]"-->
        <!--          v-model="item.tp3"-->
        <!--          item-value="value"-->
        <!--          item-text="text"-->
        <!--        ></v-select>-->
      </template>
      <template v-slot:item.tp4="{ item }">
        <div @click="alerta">
          <v-switch
            :true-value="1"
            :false-value="0"
            @change="updateAlumnoCursado(item)"
            :disabled="!disableNotas"
            :label="item.tp4 ? 'SI' : 'NO'"
            v-model="item.tp4"
          ></v-switch>
        </div>

        <!--        <v-select-->
        <!--          @change="updateAlumnoCursado(item)"-->
        <!--          :disabled="!disableNotas"-->
        <!--          :items="[-->
        <!--            { value: 0, text: 'Desaprobado' },-->
        <!--            { value: 1, text: 'Aprobado' },-->
        <!--          ]"-->
        <!--          v-model="item.tp4"-->
        <!--          item-value="value"-->
        <!--          item-text="text"-->
        <!--        ></v-select>-->
      </template>

      <template v-slot:item.nota_alumno="{ item }">
        <div @click="alerta">
          <v-text-field
            @input="updateAlumnoCursado(item)"
            :disabled="!disableNotas"
            type="number"
            v-model="item.nota_alumno"
          ></v-text-field>
        </div>
      </template>

      <template v-slot:item.cursado_aprobado="{ item }">
        <v-switch
          :true-value="1"
          :false-value="0"
          @change="updateAlumnoCursado(item)"
          :disabled="true"
          :label="item.cursado_aprobado ? 'SI' : 'NO'"
          v-model="item.cursado_aprobado"
        ></v-switch>
      </template>

      <template v-slot:item.fecha_abandono="{ item }">
        <v-row>
          <v-btn
            @click="editAbandono(item, true)"
            :color="item.fecha_abandono ? 'error' : 'light'"
            >{{
              item.fecha_abandono ? item.fecha_abandono : "registrar"
            }}</v-btn
          >
        </v-row>
      </template>

      <template v-slot:item.observaciones="{ item }">
        <v-icon color="info" class="mr-2" @click="editAbandono(item, false)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  // mapGetters,
  mapActions,
  mapGetters,
  mapState,
} from "vuex";
import AlumnoCursadoForm from "@/views/alumno-cursado/AlumnoCursadoForm";
import MyModal from "@/components/templates/MyModal";
import AlumnoCursadoPDF from "@/views/alumno-cursado/pdf/AlumnoCursadoPDF";

export default {
  name: "NotasCursadoABM",
  components: {
    AlumnoCursadoPDF,
    MyModal,
    AlumnoCursadoForm,
  },
  props: {
    uploadPath: { type: String, default: "alumno-cursados" },
    headers: Array,
    only: Array,
    cursada_id: [String, Number],
  },
  data() {
    return {
      abandono: false,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      cargaMultiple: false,
      batch: {},
      awaitingSearch: null,
      filters: {},
      options: { itemsPerPage: -1 },
      disableNotas: false,
      selectedItems: [],
      search: "",
    };
  },
  methods: {
    ...mapActions("AlumnoCursadoModule", [
      "sendMailNota",
      "batchUpdate",
      "sendMailNota",
      "getAlumnoCursadosPorCursado",
      "getAlumnoCursados",
      "getFields",
      "addAlumnoCursado",
      "updateAlumnoCursado",
      "deleteAlumnoCursado",
      "editAlumnoCursado",
      "showDialog",
      "hideDialog",
    ]),
    ...mapActions("CursadoModule", ["getCursado"]),

    async enviarMail() {
      await this.sendMailNota(this.selectedItems);
      this.reload();
    },

    close() {
      this.hideDialog();
    },
    onSubmit() {
      // this.addAlumnoCursado(this.alumnoCursado);
      this.alumnoCursado.id
        ? this.updateAlumnoCursado(this.alumnoCursado)
        : this.addAlumnoCursado(this.alumnoCursado);
      this.close();
    },
    newItem() {
      // this.fetchAlumnoCursado();
      this.editAlumnoCursado({});
      this.showDialog();
    },
    editItem(item) {
      this.editAlumnoCursado(item);
      this.showDialog();
      // this.showAlumnoCursadoDialog();
    },
    deleteItem(id) {
      this.deleteAlumnoCursado(id);
    },
    reload() {
      this.filters.cursado_id = this.cursada_id;
      this.getAlumnoCursadosPorCursado({
        filters: this.filters,
        options: this.options,
      });
    },
    notasBatchUpdate() {
      // var items = [];
      for (var i in this.selectedItems) {
        for (var j in this.batch) {
          this.selectedItems[i][j] = this.batch[j];
        }
      }
      this.batchUpdate(this.selectedItems);
    },
    alerta() {
      if (!this.disableNotas) {
        alert("Active el interruptor editar");
      }
    },

    editAbandono(item, flag) {
      this.abandono = flag;
      this.editItem(item);
    },
  },
  computed: {
    ...mapGetters("AlumnoCursadoModule", ["errors"]),
    ...mapState("AlumnoCursadoModule", [
      "alumnoCursados",
      "dialog",
      "alumnoCursado",
      "fields",
      "pagination",
    ]),
    ...mapState("CursadoModule", ["cursado"]),
    // ...mapGetters("AlumnoCursadoModule",["alumnoCursadoList","alumnoCursadoDialog","newAlumnoCursadoRecord","alumnoCursado","alumnoCursadoFields","alumnoCursadoErrors"]),
    // ...mapGetters("AlumnoCursadoModule",["errors"]),
  },
  watch: {
    options: {
      handler() {
        this.reload();
        // console.log(this.pagination);
      },
      deep: true,
    },
    filters: {
      handler() {
        this.reload();
        // console.log(this.pagination);
      },
      deep: true,
    },
    search: function () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.reload();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  created() {
    this.getCursado(this.cursada_id);
    this.getFields();
  },
};
</script>

<style scoped></style>
