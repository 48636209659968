<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content"></section>
    </vue-html2pdf>
    <v-container ref="content" fluid>
      <slot name="dialog">
        <my-modal :eager="false" :dialog="dialog" @close="close">
          <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
            ><slot :name="slot" v-bind="scope"
          /></template>

          <template slot="header"><h3>AlumnoExamens</h3></template>
          <template slot="body">
            <v-form @submit.prevent="onSubmit">
              <slot name="other-form">
                <alumnoExamen-form :only="only"> </alumnoExamen-form>
              </slot>
              <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
                ><slot :name="slot" v-bind="scope"
              /></template>
            </v-form>
          </template>
          <template v-slot:footer-btn>
            <v-btn color="blue darken-1" text @click="onSubmit">
              Guardar
            </v-btn>
          </template>
        </my-modal>
      </slot>
      <h3>
        Alumnos Examen de {{ examen.materia.nombre }} {{ examen.fechacd }}
      </h3>

      <v-switch label="Editar" v-model="disableNotas" />

      <v-btn @click="generateReport">Imprimir</v-btn>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-data-table
        ref="content"
        :search="search"
        v-model="selectedItems"
        :show-select="true"
        :items="alumnoExamens"
        :headers="headers"
      >
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>

        <template v-slot:item.nota_oral="{ item }">
          <v-text-field
            @change="updateAlumnoExamen(item)"
            type="number"
            :disabled="!disableNotas"
            :label="letras[item.nota_oral]"
            v-model="item.nota_oral"
          ></v-text-field>
        </template>
        <template v-slot:item.nota_escrito="{ item }">
          <v-text-field
            @change="updateAlumnoExamen(item)"
            type="number"
            :disabled="!disableNotas"
            :label="letras[item.nota_escrito]"
            v-model="item.nota_escrito"
          ></v-text-field>
        </template>
        <template v-slot:item.nota_final="{ item }">
          <v-text-field
            @change="updateAlumnoExamen(item)"
            type="number"
            :disabled="!disableNotas"
            :label="letras[item.nota_final]"
            v-model="item.nota_final"
          ></v-text-field>
        </template>

        <template v-slot:item.ausente="{ item }">
          <v-switch
            @change="updateAlumnoExamen(item)"
            true-value="1"
            false-value="0"
            label="Ausente"
            v-model="item.ausente"
          ></v-switch>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon color="info" class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <!--        <v-icon-->
          <!--            color="info"-->
          <!--            @click="deleteItem(item.id)"-->
          <!--        >-->
          <!--          mdi-delete-->
          <!--        </v-icon>-->
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import {
  // mapGetters,
  mapActions,
  mapState,
} from "vuex";
import AlumnoExamenForm from "@/views/alumno-examen/AlumnoExamenForm";
import MyModal from "@/components/templates/MyModal";

import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "NotasExamenABM",
  components: {
    MyModal,
    AlumnoExamenForm,
    VueHtml2pdf,
  },
  props: {
    uploadPath: { type: String, default: "alumno-examens" },
    headers: Array,
    only: Array,
    examen_id: [String, Number],
  },
  data() {
    return {
      letras: [
        "Cero",
        "Uno",
        "Dos",
        "Tres",
        "Cuatro",
        "Cinco",
        "Seis",
        "Siete",
        "Ocho",
        "Nueve",
        "Diez",
      ],
      disableNotas: false,
      selectedItems: [],
      search: "",
    };
  },
  methods: {
    ...mapActions("AlumnoExamenModule", [
      "getAlumnoExamenPorCursado",
      "getAlumnoExamens",
      "getFields",
      "addAlumnoExamen",
      "updateAlumnoExamen",
      "deleteAlumnoExamen",
      "editAlumnoExamen",
      "showDialog",
      "hideDialog",
    ]),
    ...mapActions("ExamenModule", ["getExamen"]),
    close() {
      this.hideDialog();
    },
    onSubmit() {
      // this.addAlumnoExamen(this.alumnoExamen);
      this.alumnoExamen.id
        ? this.updateAlumnoExamen(this.alumnoExamen)
        : this.addAlumnoExamen(this.alumnoExamen);
    },
    newItem() {
      // this.fetchAlumnoExamen();
      this.editAlumnoExamen({});
      this.showDialog();
    },
    editItem(item) {
      this.editAlumnoExamen(item);
      this.showDialog();
      // this.showAlumnoExamenDialog();
    },
    deleteItem(id) {
      this.deleteAlumnoExamen(id);
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  computed: {
    ...mapState("AlumnoExamenModule", [
      "alumnoExamens",
      "dialog",
      "alumnoExamen",
      "fields",
    ]),
    ...mapState("ExamenModule", ["examen"]),
    // ...mapGetters("AlumnoExamenModule",["alumnoExamenList","alumnoExamenDialog","newAlumnoExamenRecord","alumnoExamen","alumnoExamenFields","alumnoExamenErrors"]),
    // ...mapGetters("AlumnoExamenModule",["errors"]),
  },
  created() {
    this.getAlumnoExamenPorCursado(this.examen_id);
    // this.getAlumnoExamens()
    this.getExamen(this.examen_id);
    this.getFields();
  },
};
</script>

<style scoped></style>
