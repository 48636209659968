import Correlativas from "@/apis/Correlativas";

const actions = {
  showDialog({ commit }) {
    commit("showDialog");
  },

  hideDialog({ commit }) {
    commit("hideDialog");
  },

  editCorrelativas({ commit }, model) {
    commit("setModel", model);
  },

  async addCorrelativas({ commit }, item) {
    try {
      const { status, data } = await Correlativas.store(item);
      if (status === 201) {
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteCorrelativas({ commit }, id) {
    try {
      const { status } = await Correlativas.delete(id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateCorrelativas({ commit }, model) {
    try {
      const { status, data } = await Correlativas.update(model);
      if (status === 200) {
        console.log(data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getCorrelativasPorMateria({ commit }, bundle) {
    try {
      const { status, data } = await Correlativas.porMaterias(bundle);
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getCorrelativass({ commit }) {
    try {
      const { status, data } = await Correlativas.all();
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await Correlativas.model();
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};

export default actions;
