const mutations = {
  clearUser(state) {
    state.user = { roles: [] };
  },

  clearDocumentacion(state) {
    state.documentacion = {
      dni_frente: {},
      dni_dorso: {},
      cuil: {},
      formulario_inscripcion: {},
      formulario_medico: {},
    };
  },

  setDniFrente(state, bundle) {
    state.documentacion.dni_frente = { data: bundle.data, type: bundle.type };
    state.key++;
  },
  setDniDorso(state, bundle) {
    state.documentacion.dni_dorso = { data: bundle.data, type: bundle.type };
    state.key++;
  },
  setCuil(state, bundle) {
    state.documentacion.cuil = { data: bundle.data, type: bundle.type };
    state.key++;
  },
  setFormularioInscripcion(state, bundle) {
    state.documentacion.formulario_inscripcion = {
      data: bundle.data,
      type: bundle.type,
    };
    state.key++;
  },
  setFormularioMedico(state, bundle) {
    state.documentacion.formulario_medico = {
      data: bundle.data,
      type: bundle.type,
    };
    state.key++;
  },

  setAvatar(state, data) {
    state.avatar = data;
    // state.avatar = URL.createObjectURL(data);
  },

  setAlumnos(state, data) {
    state.alumnos = data.models;
    state.pagination.totalCount = +data.headers["x-pagination-total-count"];
  },
  setDocentes(state, models) {
    state.docentes = models;
  },

  showDialog(state) {
    state.dialog = true;
  },

  hideDialog(state) {
    state.dialog = false;
  },

  setModel(state, model) {
    state.errorArray = [];
    state.user = model;
    state.defaultCarrera = model.carreras ? model.carreras[0] : "";
  },

  setIngresantes(state, data) {
    state.ingresantes = data.models;
    state.pagination.totalCount = +data.headers["x-pagination-total-count"];
  },

  setModels(state, data) {
    state.users = data.models;
    // state.total = models._meta.totalCount;
    state.pagination.totalCount = +data.headers["x-pagination-total-count"];
    // state.pagination.page = models._meta.currentPage;
    // state.pagination.itemsPerPage = models._meta.perPage;
    // console.log(models);
  },

  addModel(state, model) {
    state.nuevo = true;
    state.users.unshift(model);
  },

  delModel(state, id) {
    const removeIndex = state.users
      .map(function (item) {
        return item.id;
      })
      .indexOf(id);
    state.users.splice(removeIndex, 1);
  },

  setErrors(state, errors) {
    state.errorArray = errors;
  },

  setFields(state, data) {
    state.fields = data.fields;
    state.emptyModel = data.model;
  },
};

export default mutations;
