<template>
  <div>
    <h5>{{ examen.materia.searchTag }}</h5>
    <!--    <div class="title">Comisión: {{ cursado.comision }}</div>-->
    <div>
      <div>Docentes:</div>
      <div class="d-flex flex-wrap">
        <div v-for="(docente, i) in docentes" :key="i">
          <div>
            <v-chip dark color="info" class="m-1">
              {{ docente }}
            </v-chip>
          </div>
        </div>
      </div>
    </div>
    <div>Horario: {{ examen.fechaFormateada }}</div>
    <download-excel :name="exportFile" :data="excel">
      <v-btn block dark color="">Exportar</v-btn> </download-excel
    ><!--    <v-divider></v-divider>-->
    <v-list three-line>
      <div v-for="(item, i) in alumnos" :key="item.id">
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-avatar>
            <!--            <v-img></v-img>-->
            <v-icon x-large color="primary">mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content
            :class="
              item.roles.includes('alumno-presistema')
                ? 'error p-3 text-light'
                : ''
            "
          >
            <v-list-item-title>
              {{ i + 1 }}- {{ item.apellido }}, {{ item.nombre }} ({{
                item.condicion
              }})
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-btn
                class="text-lowercase"
                :color="
                  item.roles.includes('alumno-presistema') ? 'dark' : 'primary'
                "
                outlined
                :href="'mailto:' + item.email"
                >{{ item.email }}</v-btn
              >
            </v-list-item-subtitle>
            <span v-if="item.roles.includes('alumno-presistema')"
              >Estudiante pendiente de verificación</span
            >
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "AlumnosExamenListDocente",
  props: {
    examen_id: [String, Number],
    // cursado:{type:Object,default:()=>{}},
  },
  data() {
    return {
      filters: {
        examen_id: this.examen_id,
      },
    };
  },
  computed: {
    ...mapState("AlumnoExamenModule", ["alumnoExamens"]),
    ...mapState("ExamenModule", ["examen"]),

    exportFile() {
      const name =
        this.examen.materia.searchTag + "-" + this.examen.fechaFormateada;
      return name;
    },

    docentes() {
      return this.examen.docentes.map((el) => el.apellido + ", " + el.nombre);
    },

    alumnos() {
      let alumnos = this.alumnoExamens.map((el) => {
        let alumno = el.alumno;
        alumno.condicion = el.condicion;
        return alumno;
      });

      return alumnos.sort(this.compare);
    },
    excel() {
      let items = [];
      for (var i in this.alumnos) {
        const alumno = this.alumnos[i];
        const registro = {
          nro: +i + 1,
          apellido: alumno.apellido,
          nombre: alumno.nombre,
          dni: alumno.dni,
          email: alumno.email,
          estado: alumno.roles.includes("alumno-presistema")
            ? "Pendiente"
            : "OK",
        };
        items.push(registro);
      }
      return items;
    },
  },
  methods: {
    ...mapActions("AlumnoExamenModule", ["getAlumnoExamens"]),
    ...mapActions("ExamenModule", ["getExamen"]),
    reload() {
      this.getAlumnoExamens({
        filters: this.filters,
        options: {
          itemsPerPage: -1,
        },
      });
    },
    compare(a, b) {
      if (a.apellido < b.apellido) {
        return -1;
      }
      if (a.apellido > b.apellido) {
        return 1;
      }
      return 0;
    },
  },
  created() {
    this.getExamen({
      id: this.examen_id,
      expand: ["materia", "espacio", "docentes", "alumnos"].toString(),
    });
    this.reload();
  },
};
</script>

<style scoped></style>
