import Ingresante from "@/apis/Ingresante";

const actions = {
  clearErrors({ commit }) {
    commit("setErrors", {});
  },

  async deleteAll({ commit }, items) {
    try {
      await Ingresante.deleteAll(items);
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  showDialog({ commit }) {
    commit("showDialog");
  },

  hideDialog({ commit }) {
    commit("hideDialog");
  },

  editIngresante({ commit }, model) {
    commit("setModel", model);
  },

  async addIngresante({ commit }, item) {
    try {
      const { status, data } = await Ingresante.store(item);
      if (status === 201) {
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  async matricular({ commit }, items) {
    try {
      const { status, data } = await Ingresante.matricular(items);
      if (status === 201) {
        commit("setModels", data);
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },

  async deleteIngresante({ commit }, id) {
    try {
      const { status } = await Ingresante.delete(id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  async updateIngresante({ commit }, model) {
    try {
      const { status, data } = await Ingresante.update(model);
      if (status === 200) {
        console.log(data);
        commit("updateModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },

  async getIngresantes({ commit }, bundle) {
    try {
      const { status, data, headers } = await Ingresante.all(bundle);
      if (status === 200) {
        commit("setModels", { models: data, headers: headers });
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response.data);
    }
  },

  async getIngresantesCarrera({ commit }, bundle) {
    try {
      const { status, data, headers } = await Ingresante.getCarrera(bundle);
      if (status === 200) {
        commit("setCarrera", { models: data, headers: headers });
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response.data);
    }
  },

  async getMatriculados({ commit }, flag) {
    try {
      const { status, data, headers } = await Ingresante.matriculados(flag);
      if (status === 200) {
        commit("setModels", { models: data, headers: headers });
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response.data);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await Ingresante.model();
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response.data);
    }
  },
};

export default actions;
