import User from "@/apis/User";
import Recovery from "@/apis/Recovery";
const actions = {
  async getAvatar({ commit }, item) {
    try {
      const { status, data } = await User.getAvatar(item);
      // console.log('response: ',data);
      if (status === 200) {
        var reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          if (imageDataUrl) {
            // console.log('imageDataUrl', imageDataUrl);
            commit("setAvatar", imageDataUrl);
          }
        };
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },

  async getFrenteDni({ commit }, bundle) {
    try {
      bundle.field = "dni_frente";
      const { status, data } = await User.getDocumentacion(bundle);
      if (status === 200) {
        bundle.type = data.type;
        var reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          bundle.data = imageDataUrl;
          commit("setDniFrente", bundle);
        };
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  async getDniDorso({ commit }, bundle) {
    try {
      bundle.field = "dni_dorso";
      const { status, data } = await User.getDocumentacion(bundle);
      if (status === 200) {
        bundle.type = data.type;
        var reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          bundle.data = imageDataUrl;
          commit("setDniDorso", bundle);
        };
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  async getCuil({ commit }, bundle) {
    try {
      bundle.field = "cuil";
      const { status, data } = await User.getDocumentacion(bundle);
      if (status === 200) {
        console.log(data);
        bundle.type = data.type;
        var reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          bundle.data = imageDataUrl;
          commit("setCuil", bundle);
        };
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  async getFormularioInscripcion({ commit }, bundle) {
    try {
      bundle.field = "formulario_inscripcion";
      const { status, data } = await User.getDocumentacion(bundle);
      if (status === 200) {
        bundle.type = data.type;
        var reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          bundle.data = imageDataUrl;
          commit("setFormularioInscripcion", bundle);
        };
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  async getFormularioMedico({ commit }, bundle) {
    try {
      bundle.field = "formulario_medico";
      const { status, data } = await User.getDocumentacion(bundle);
      if (status === 200) {
        bundle.type = data.type;
        var reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          bundle.data = imageDataUrl;
          commit("setFormularioMedico", bundle);
        };
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },

  async uploadImagen({ commit }, item) {
    try {
      const { status, data } = await User.upload(item);
      if (status === 200) {
        console.log(data);
        // commit("setModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  async uploadDocumentacion({ commit }, item) {
    try {
      const { status, data } = await User.uploadDocumentacion(item);
      if (status === 200) {
        console.log(data);
        // commit("setModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },

  async deleteAll({ commit }, items) {
    try {
      // const {status,data,headers} =
      await User.deleteAll(items);
      // if(status===200){
      //   commit("setModels", { models: data, headers: headers });
      // }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  showDialog({ commit }) {
    commit("showDialog");
  },

  hideDialog({ commit }) {
    commit("hideDialog");
  },

  editUser({ commit }, model) {
    commit("setModel", model);
  },

  async addIngresante({ commit }, item) {
    try {
      const { status, data } = await User.registrarIngresante(item);
      if (status === 201) {
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async addalumnoExistente({ commit }, item) {
    try {
      const { status, data } = await User.registrarAlumnoExistente(item);
      if (status === 201) {
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async addDocente({ commit }, item) {
    try {
      const { status, data } = await User.registrarDocente(item);
      if (status === 201) {
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async addUser({ commit }, item) {
    try {
      const { status, data } = await User.register(item);
      if (status === 201) {
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async recovery({ commit }, item) {
    try {
      const { status, data } = await Recovery.request(item);
      if (status === 200) {
        return data;
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteUser({ commit }, id) {
    try {
      const { status } = await User.delete(id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateUser({ commit }, model) {
    try {
      const { status, data } = await User.update(model);
      if (status === 200) {
        console.log(data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async cambiarRol({ commit }, model) {
    try {
      const { status, data } = await User.cambiarRol(model);
      if (status === 200) {
        console.log(data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getIngresantes({ commit }, opciones) {
    try {
      const { status, data, headers } = await User.porRol(opciones);
      if (status === 200) {
        commit("setIngresantes", { models: data, headers: headers });
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getUsers({ commit }, opciones) {
    try {
      const { status, data, headers } = await User.all(opciones);
      if (status === 200) {
        commit("setModels", { models: data, headers: headers });
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async getUser({ commit }, bundle) {
    // console.log(this.currentUser);
    try {
      const { status, data } = await User.data(bundle);
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }

    // return this.currentUser;
  },
  async getPorRol({ commit }, bundle) {
    try {
      const { status, data, headers } = await User.porRol(bundle);
      if (status === 200) {
        commit("setModels", { models: data, headers: headers });
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async getDocentes({ commit }, bundle) {
    bundle.rol = "docente";
    try {
      const { status, data } = await User.porRol(bundle);
      if (status === 200) {
        commit("setDocentes", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async getAlumnos({ commit }, bundle) {
    try {
      const { status, data, headers } = await User.alumnos(bundle);
      if (status === 200) {
        commit("setModels", { models: data, headers: headers });
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async getAlumnosPorCarrera({ commit }, bundle) {
    try {
      const { status, data, headers } = await User.porCarrera(bundle);
      if (status === 200) {
        commit("setAlumnos", { models: data, headers: headers });
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await User.model();
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};

export default actions;
