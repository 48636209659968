import Carrera from "@/apis/Carrera";

const actions = {
  showDialog({ commit }) {
    commit("showDialog");
  },

  hideDialog({ commit }) {
    commit("hideDialog");
  },

  editCarrera({ commit }, model) {
    commit("setModel", model);
  },

  async addCarrera({ commit }, item) {
    try {
      const { status, data } = await Carrera.store(item);
      if (status === 201) {
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteCarrera({ commit }, id) {
    try {
      const { status } = await Carrera.delete(id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateCarrera({ commit }, model) {
    try {
      const { status, data } = await Carrera.update(model);
      if (status === 200) {
        console.log(data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getCarrera({ commit }, id) {
    try {
      const { status, data } = await Carrera.view(id);
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  setCarrera({ commit }, model) {
    commit("setModel", model ? model : {});
  },

  async getCarreras({ commit }) {
    try {
      const { status, data } = await Carrera.all();
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await Carrera.model();
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};

export default actions;
