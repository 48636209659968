import ActiveModel from "@/apis/ActiveModel";
import ProgramaExamen from "@/apisNew/ProgramaExamen";

const END_POINT = "programa-examen";

const actions = {
  async getProgramaExamenPdf({ commit }, id) {
    try {
      const { status, data } = await ProgramaExamen.downloadPdf(id);
      if (status === 200) {
        // commit("setModel", data);
        console.log(data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response.data);
    }
  },

  async getProgramaExamens({ commit }, bundle) {
    try {
      const { status, data } = await ActiveModel.all(END_POINT, bundle);
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async getProgramaExamen({ commit }, bundle) {
    try {
      const { status, data } = await ActiveModel.viewWithParams(
        END_POINT,
        bundle.id,
        bundle
      );
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response.data);
    }
  },

  async addProgramaExamen({ commit }, item) {
    try {
      const { status, data } = await ActiveModel.store(END_POINT, item);
      if (status === 201) {
        console.log("registro creado");
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteProgramaExamen({ commit }, id) {
    try {
      const { status } = await ActiveModel.delete(END_POINT, id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateProgramaExamen({ commit }, model) {
    try {
      const { status, data } = await ActiveModel.update(END_POINT, model);
      if (status === 200) {
        commit("updateModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await ActiveModel.model(END_POINT);
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};
export default actions;
