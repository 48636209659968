import { httpClient } from "./Api";

const END_POINT = "user";

export default {
  getAvatar(data) {
    return httpClient.post(END_POINT + "/custom/getavatar", data, {
      responseType: "blob",
    });
  },
  getDocumentacion(bundle) {
    // console.log(bundle);
    const params = {
      // ...bundle.user,
      field: bundle.field,
    };
    return httpClient.get(END_POINT + "/custom/getdocumentacion", {
      params: params,
      responseType: "blob",
    });
  },

  upload(data) {
    return httpClient.post(END_POINT + "/custom/uploadimage", data);
  },
  uploadDocumentacion(data) {
    return httpClient.post(END_POINT + "/custom/uploaddocumentacion", data);
  },

  deleteAll(items) {
    return httpClient.delete(END_POINT + "/custom/deleteall", {
      data: { items: items },
    });
  },

  porCarrera(bundle) {
    const params = {
      expand: [].toString(),
      carrera: bundle.carrera_id,
      options: bundle.options,
      search: bundle.search,
      filters: bundle.filters,
    };
    return httpClient.get(END_POINT + "/porcarrera", { params });
  },

  alumnos(bundle) {
    // console.log(options)
    const params = {
      expand: ["roles"].toString(),
      options: bundle.options,
      filters: bundle.filters,
      // search: bundle.search,
      rol: "alumno",
    };
    return httpClient.get(END_POINT + "/porrol", { params });
  },

  docentes(bundle) {
    // console.log(options)
    const params = {
      expand: ["roles"].toString(),
      options: bundle.options,
      filters: bundle.filters,
      // search: bundle.search,
      rol: "docente",
    };
    return httpClient.get(END_POINT + "/porrol", { params });
  },

  porRol(bundle) {
    const expand = bundle.expand ? bundle.expand : ["roles"];
    const params = {
      expand: expand.toString(),
      options: bundle.options,
      filters: bundle.filters,
    };
    return httpClient.get(END_POINT + "/porrol", { params });
  },
  all(bundle) {
    // console.log(options)
    const params = {
      expand: ["roles"].toString(),
      search: bundle.search,
      itemsPerPage: bundle.options.itemsPerPage,
      page: bundle.options.page,
      options: bundle.options,
      filters: bundle.filters,
    };
    return httpClient.get(END_POINT, { params });
  },
  data() {
    // console.log(bundle);
    const params = {
      expand: ["roles", "espacios", "rolesName"].toString(),
      // ...bundle,
    };
    return httpClient.get(END_POINT + "/data", { params });
  },

  store(data) {
    return httpClient.post(END_POINT, data);
  },

  delete(id) {
    return httpClient.delete(`${END_POINT}/${id}`);
  },

  update(item) {
    return httpClient.put(`${END_POINT}/${item.id}`, item);
  },
  cambiarRol(item) {
    return httpClient.put(`${END_POINT}/custom/cambiarrol`, item);
  },
  view(id) {
    const params = {
      expand: [].toString(),
    };
    return httpClient.get(`${END_POINT}/${id}`, { params });
  },
  model() {
    return httpClient.get(`${END_POINT}/model`);
  },
  register(data) {
    return httpClient.post(`${END_POINT}/register`, data);
  },
  registrarIngresante(data) {
    return httpClient.post(`${END_POINT}/custom/registraringresante`, data);
  },
  registrarAlumnoExistente(data) {
    return httpClient.post(
      `${END_POINT}/custom/registraralumnoexistente`,
      data
    );
  },
  registrarDocente(data) {
    return httpClient.post(`${END_POINT}/custom/registrardocente`, data);
  },
};
