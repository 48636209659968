import { httpClient } from "./Api";
import Vue from "vue";

const END_POINT = "examen";

export default {
  cargarPromocionados(id) {
    const params = { examen_id: id };
    return Vue.prototype.$axios.get(END_POINT + "/custom/cargarpromocionados", {
      params,
    });
  },

  crearBulkExamen(data) {
    console.log("data", data);
    return Vue.prototype.$axios.post(END_POINT + "/custom/bulkcreate", data);
  },

  disponibleInscripcion(carrera) {
    const params = {
      expand: ["materia", "carrera", "docentes", "docenteExamen"].toString(),
      carrera: carrera.carrera_id,
    };
    return httpClient.get(END_POINT + "/custom/vigente", { params });
  },
  porAlumno() {
    const params = {
      expand: ["materia", "carrera", "docentes", "docenteExamen"].toString(),
    };
    return httpClient.get(END_POINT + "/custom/poralumno", { params });
  },

  porDocente() {
    const params = {
      expand: ["materia", "carrera", "docentes", "docenteExamen"].toString(),
    };
    return httpClient.get(END_POINT + "/custom/pordocente", { params });
  },

  all(bundle) {
    const params = {
      expand: ["materia", "carrera", "docentes", "docenteExamen"].toString(),
      options: bundle.options,
    };
    return httpClient.get(END_POINT, { params });
  },

  store(data) {
    return httpClient.post(END_POINT, data);
  },

  delete(id) {
    return httpClient.delete(`${END_POINT}/${id}`);
  },

  update(item) {
    return httpClient.put(`${END_POINT}/${item.id}`, item);
  },
  view(id) {
    const params = {
      expand: ["materia", "espacio"].toString(),
    };
    return httpClient.get(`${END_POINT}/${id}`, { params });
  },
  model() {
    return httpClient.get(`${END_POINT}/model`);
  },
};
