export default {
  periodoExamen: {},
  periodoExamens: [],
  errorArray: [],
  fields: {},
  record: {
    nuevo: false,
    actualizado: false,
  },
  pagination: {},
};
