const getters = {
  errors(state) {
    var merrors = {};
    for (var i in state.errorArray.errors) {
      merrors[i] = state.errorArray.errors[i][0];
    }
    return merrors;
  },
};

export default getters;
