<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
      <div>
        <v-card class="">
          <v-alert v-model="sended" border="top" color="success lighten-2" dark>
            {{ sendedMessage }}
          </v-alert>
          <v-alert v-model="error" border="top" color="error lighten-2" dark>
            {{ errorMessage }}
          </v-alert>
          <v-card-title class="text-h6 primary lighten-1 text-light mb-3">
            <div class="d-flex justify-content-center w-100">
              <div>
                <img
                  :src="require('@/assets/logoifdc3.png')"
                  height="50"
                  width="50"
                  class="mr-3"
                />
              </div>
              <div class="text-center">
                <div>San Antonio</div>
                <div>Oeste</div>
              </div>
            </div>
            <!--              Login Sistema IFDC SAO-->
          </v-card-title>
          <v-card-text>
            <v-form
              @submit.prevent="recuperar"
              ref="loginForm"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="form.email"
                :rules="[rules.required]"
                label="Email de usuario o DNI"
              ></v-text-field>
            </v-form>
            <v-btn x-large block color="primary" @click="recuperar">
              Recuperar Contraseña
            </v-btn>
            <div class="d-flex justify-space-between">
              <div>
                <v-btn
                  @click="$router.push({ name: 'login' })"
                  class="mt-3"
                  color="secondary"
                  text
                  >Volver al Login</v-btn
                >
              </div>
              <!--          <div>-->
              <!--          <v-btn class="mt-3" color="primary" text >Olvide Contraseña</v-btn>-->
              <!--          </div>-->
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Recovery from "@/apis/Recovery";

export default {
  name: "RecuperarPassword",
  computed: {},
  methods: {
    async recuperar() {
      this.sended = false;
      this.error = false;
      try {
        const { status, data } = await Recovery.request(this.form);
        if (status === 200) {
          // this.form = {};
          console.log(data);
          this.sendedMessage = data;
          this.sended = true;
        }
      } catch (e) {
        console.log(e.response.data);
        this.errorMessage = e.response.data;
        this.error = true;
        // commit("setErrors", e.response.data);
      }
    },
  },
  data() {
    return {
      error: false,
      errorMessage: "",
      sended: false,
      sendedMessage: "",
      form: {},
      dialog: true,
      valid: true,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
    };
  },
};
</script>

<style scoped></style>
