import ActiveModel from "@/apis/ActiveModel";
import Examen from "@/apis/Examen";

const END_POINT = "examen";

const actions = {
  async cargarPromocionados({ commit }, id) {
    try {
      const { status, data } = await Examen.cargarPromocionados(id);
      if (status === 201) {
        console.log("promocionados cargados");
        console.log(data);
        // commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async addBulkExamen({ commit }, item) {
    console.log("item", item);
    try {
      const { status, data } = await Examen.crearBulkExamen(item);
      if (status === 201) {
        console.log("registro masivo creado");
        console.log(data);
        // commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getDisponibleInscripcion({ commit }, carrera) {
    try {
      const { status, data } = await Examen.disponibleInscripcion(carrera);
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getExamens({ commit }, bundle) {
    try {
      const { status, data } = await ActiveModel.all(END_POINT, bundle);
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response.data);
    }
  },
  // async getExamen({ commit }, id) {
  //   try {
  //     const { status, data } = await ActiveModel.view(id);
  //     if (status === 200) {
  //       commit("setModel", data);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     commit("setErrors", e.response.data);
  //   }
  // },
  async getExamen({ commit }, bundle) {
    try {
      const { status, data } = await ActiveModel.viewWithParams(
        END_POINT,
        bundle.id,
        bundle
      );
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response.data);
    }
  },

  async addExamen({ commit }, item) {
    try {
      const { status, data } = await ActiveModel.store(END_POINT, item);
      if (status === 201) {
        console.log("registro creado");
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteExamen({ commit }, id) {
    try {
      const { status } = await ActiveModel.delete(END_POINT, id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateExamen({ commit }, model) {
    try {
      const { status, data } = await ActiveModel.update(END_POINT, model);
      if (status === 200) {
        commit("updateModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await ActiveModel.model(END_POINT);
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};
export default actions;
