import Vue from "vue";

const END_POINT = "docente-examen";

export default {
  actaExamen(examen) {
    const params = {
      // examen: examen,
    };
    return Vue.prototype.$axios.post(END_POINT + "/custom/actaexamen", examen, {
      params: params,
      responseType: "blob",
    });
  },

  customQuery() {
    const params = {};
    return Vue.prototype.$axios.get(END_POINT + "/custom/query", { params });
  },
};
