export default {
  uCurricularCarrera: { docentes: [] },
  materiasPorCarrera: [],
  uCurricularCarreras: [],
  fields: {},
  errorArray: [],
  dialog: false,
  pagination: {},
  bundle: {},
  filters: {},
};
