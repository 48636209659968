import ActiveModel from "@/apis/ActiveModel";
import NotaAlumnoExamen from "@/apisNew/NotaAlumnoExamen";

const END_POINT = "nota-alumno-examen";

const actions = {
  async getNotaAlumnoExamensAprobados({ commit }, bundle) {
    try {
      const { status, data } = await NotaAlumnoExamen.getAprobados(bundle);
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteFichaAcademica({ commit }, id) {
    const { status, data } = await NotaAlumnoExamen.deleteFicha(id);
    if (status === 200) {
      // console.log("registro creado");
      commit("delModel", data);
    }
  },

  async updateFichaAcademica({ commit }, model) {
    const { status, data } = await NotaAlumnoExamen.updateFicha(model);
    if (status === 200) {
      // console.log("registro creado");
      commit("updateModel", data);
    }
  },

  async addFichaAcademica({ commit }, model) {
    const { status, data } = await NotaAlumnoExamen.guardarFicha(model);
    if (status === 200) {
      console.log("registro creado");
      commit("addModel", data);
    }
  },

  async getNotaAlumnoExamens({ commit }, bundle) {
    try {
      const { status, data } = await ActiveModel.all(END_POINT, bundle);
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async getNotaAlumnoExamen({ commit }, id) {
    try {
      const { status, data } = await ActiveModel.view(END_POINT, id);
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getNotaAlumnoExamenWithParams({ commit }, bundle) {
    try {
      const { status, data } = await ActiveModel.viewWithParams(
        END_POINT,
        bundle.id,
        bundle
      );
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async addNotaAlumnoExamen({ commit }, item) {
    try {
      const { status, data } = await ActiveModel.store(END_POINT, item);
      if (status === 201) {
        console.log("registro creado");
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteNotaAlumnoExamen({ commit }, id) {
    try {
      const { status } = await ActiveModel.delete(END_POINT, id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateNotaAlumnoExamen({ commit }, model) {
    try {
      const { status, data } = await ActiveModel.update(END_POINT, model);
      if (status === 200) {
        commit("updateModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await ActiveModel.model(END_POINT);
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};
export default actions;
