<template>
  <v-dialog
    :retain-focus="false"
    :eager="eager"
    v-model="dialog"
    :max-width="maxWidth"
    @click:outside="close"
    scrollable
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>

    <v-card :min-height="minHeight">
      <v-card-title>
        <slot name="header"></slot>
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <slot name="body"></slot>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <slot name="footer-btn"></slot>

        <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MyModal",
  props: {
    eager: {
      type: Boolean,
      default: false,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 1200,
    },
    minHeight: {
      type: Number,
      default: 500,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
