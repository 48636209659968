export default {
  ingresante: {},
  ingresantes: [],
  fields: {},
  errorArray: [],
  dialog: false,
  pagination: {},
  ingresantesCarrera: [],
  nuevo: false,
  actualizado: false,
};
