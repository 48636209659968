<template>
  <div></div>
</template>

<script>
import authService from "@/services/auth.service";
import { mapActions } from "vuex";

export default {
  name: "Logout",
  methods: {
    ...mapActions("UserModule", ["editUser"]),
    async logout() {
      await authService.logout();
      this.$router.push({ name: "Default" });
    },
  },
  created() {
    this.editUser({});
    this.logout();
  },
};
</script>

<style scoped></style>
