const mutations = {
  showDialog(state) {
    state.dialog = true;
  },

  hideDialog(state) {
    state.dialog = false;
  },

  setModel(state, model) {
    state.errorArray = [];
    state.correlativas = model;
  },

  setModels(state, models) {
    state.correlativass = models;
  },

  addModel(state, model) {
    state.correlativass.unshift(model);
  },

  delModel(state, id) {
    const removeIndex = state.correlativass
      .map(function (item) {
        return item.id;
      })
      .indexOf(id);
    state.correlativass.splice(removeIndex, 1);
  },

  setErrors(state, errors) {
    state.errorArray = errors;
  },

  setFields(state, data) {
    state.fields = data.fields;
    state.emptyModel = data.model;
  },
};

export default mutations;
