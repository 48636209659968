export default {
  alumnoCarrera: {},
  alumnoCarreras: [],
  errorArray: [],
  fields: {},
  record: {
    nuevo: false,
    actualizado: false,
  },
  pagination: {},
};
