<template>
  <v-dialog persistent eager v-model="dialog" max-width="600">
    <v-card>
      <v-alert v-if="record.actualizado" dark color="warning"
        >Registro Actualizado</v-alert
      >
      <v-alert v-if="record.nuevo" dark color="success"
        >Registro Creado</v-alert
      >
      <v-card-title>
        ProgramaExamens
        <v-btn link icon absolute top right @click="cerrar"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-select
            placeholder="Seleccione Carrera"
            outlined
            v-model="model.carrera.id"
            :items="carreras"
            item-text="nombre"
            item-value="id"
          />
          <v-autocomplete
            placeholder="Seleccione Materia"
            outlined
            v-model="model.materia_id"
            :items="uCurricularCarreras"
            item-text="searchTag"
            item-value="id"
          >
          </v-autocomplete>
          <!--          <v-autocomplete-->
          <!--            placeholder="Seleccione Periodo"-->
          <!--            outlined-->
          <!--            v-model="model.periodo_examen_id"-->
          <!--            :items="periodoExamens"-->
          <!--            item-text="nombre"-->
          <!--            item-value="id"-->
          <!--          >-->
          <!--          </v-autocomplete>-->

          <v-select
            placeholder="Seleccione Cuatrimestre"
            outlined
            v-model="model.cuatrimestre"
            :items="['PRIMERO', 'SEGUNDO', 'ANUAL']"
          />

          <div v-for="(label, field) in filteredFields" :key="field">
            <slot :name="field" :model="model">
              <v-text-field
                outlined
                v-model="model[field]"
                :label="label"
                :error-messages="errors[field]"
              ></v-text-field>
            </slot>
          </div>
          <v-select
            placeholder="Seleccione Año"
            outlined
            v-model="model.anio"
            :items="anios"
          />
          <v-select
            placeholder="Seleccione tipo de programa"
            outlined
            v-model="model.condicion"
            :items="menu"
            item-text="value"
            item-value="id"
          />
          <v-file-input
            label="Subir Archivo"
            outlined
            v-model="model.file"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div>
          <v-btn @click="guardar" color="primary">Guardar</v-btn>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="cerrar">Cancelar</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "ProgramaExamenForm",
  props: {
    id: [String, Number],
  },
  data() {
    return {
      anios: [
        2030,
        2029,
        2028,
        2027,
        2026,
        2025,
        2024,
        2023,
        2022,
        2021,
        2020,
        2019,
        2018,
        2017,
        2016,
        2015,
        2014,
        2013,
        2012,
        2011,
        2010,
        2009,
        2008,
        2007,
        2006,
        2005,
        2004,
        2003,
        2002,
        2001,
        2000,
      ],
      menu: [
        { id: "CURSADO", value: "PROGRAMA DE CURSADO" },
        { id: "EXAMEN", value: "PROGRAMA DE EXAMEN REGULAR" },
      ],
      dialog: false,
      only: [],
      exclude: [
        "materia_id",
        "periodo_examen_id",
        "created_by",
        "fecha_publicado",
        "condicion",
        "estado",
        "descripcion",
        "archivo_examen",
        "cuatrimestre",
        "anio",
      ],
      model: { carrera: {} },
      // carrera_id: null,
    };
  },
  computed: {
    ...mapState("PeriodoExamenModule", ["periodoExamens"]),
    ...mapState("NewUCurricularCarreraModule", ["uCurricularCarreras"]),
    ...mapState("UCurricularCarreraModule", ["materiasPorCarrera"]),
    ...mapState("CarreraModule", ["carreras"]),
    ...mapGetters("ProgramaExamenModule", ["errors"]),
    ...mapState("ProgramaExamenModule", ["fields", "programaExamen", "record"]),
    filteredFields() {
      let items = [];
      items = Object.keys(this.fields)
        .filter((key) => !this.only.length > 0 || this.only.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.fields[key];
          return obj;
        }, {});
      items = Object.keys(items)
        .filter((key) => !this.exclude.includes(key))
        .reduce((obj, key) => {
          obj[key] = items[key];
          return obj;
        }, {});
      return items;
    },
  },
  methods: {
    ...mapActions("NewUCurricularCarreraModule", ["getUCurricularCarreras"]),
    ...mapActions("PeriodoExamenModule", ["getPeriodoExamens"]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("ProgramaExamenModule", [
      "getFields",
      "getProgramaExamen",
      "updateProgramaExamen",
      "addProgramaExamen",
    ]),
    async getModel() {
      await this.getProgramaExamen({
        id: this.id,
        expand: ["carrera"].toString(),
      });
      this.model = this.programaExamen;
    },
    async guardar() {
      const formData = new FormData();
      formData.append("file", this.model.file);
      for (const field in this.model) {
        formData.append(field, this.model[field]);
      }
      this.model.id
        ? await this.updateProgramaExamen(formData)
        : await this.addProgramaExamen(formData);
      // this.$emit('close');
    },
    cerrar() {
      this.record.actualizado = false;
      this.record.nuevo = false;
      this.$emit("close");
    },
    getMaterias() {
      this.getUCurricularCarreras({
        filters: { carrera_id: this.model.carrera.id },
      });
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.getModel();
      }
    },
    "model.carrera.id": function () {
      this.getMaterias();
    },
  },
  created() {
    this.getCarreras();
    this.getPeriodoExamens({});
    this.getFields();
    if (this.id) {
      this.getModel();
    }
  },
};
</script>

<style scoped></style>
