import ActiveModel from "@/apis/ActiveModel";
import AlumnoExamen from "@/apisNew/AlumnoExamen";

const END_POINT = "alumno-examen";

const actions = {
  async actaExamen({ commit }, examen) {
    try {
      const { status, data } = await AlumnoExamen.actaExamen(examen);
      if (status === 200) {
        const filename =
          examen.carrera.codigo +
          examen.materia.nombre +
          examen.turno +
          examen.instancia +
          ".xlsx";
        const url = URL.createObjectURL(
          new Blob([data], {
            // type: "application/vnd.ms-excel",
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  async sendMailInscripcion({ commit }, id) {
    try {
      const { status, data } = await AlumnoExamen.sendMailInscripcion(id);
      if (status === 200) {
        console.log(data);
        // commit("setModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },

  async getAlumnoExamens({ commit }, bundle) {
    try {
      const { status, data } = await ActiveModel.all(END_POINT, bundle);
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e.response ? e.response.data : e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async getAlumnoExamensPorAlumno({ commit }, bundle) {
    try {
      // if(!bundle.filters.alumno_id){
      //   console.log(bundle);
      //   commit("setModels",{data:[],_meta:{}});
      //   return;
      // }
      const { status, data } = await AlumnoExamen.misExamenes(bundle);
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e.response ? e.response.data : e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getAlumnoExamen({ commit }, id) {
    try {
      const { status, data } = await ActiveModel.view(END_POINT, id);
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async addAlumnoExamen({ commit }, item) {
    try {
      const { status, data } = await ActiveModel.store(END_POINT, item);
      if (status === 201) {
        console.log("registro creado");
        commit("addModel", data);
        return data;
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteAlumnoExamen({ commit }, id) {
    try {
      const { status } = await ActiveModel.delete(END_POINT, id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateAlumnoExamen({ commit }, model) {
    try {
      const { status, data } = await ActiveModel.update(END_POINT, model);
      if (status === 200) {
        commit("updateModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await ActiveModel.model(END_POINT);
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};
export default actions;
