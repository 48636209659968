export default {
  docenteEspacio: { carrera: {}, espacio: {}, materia: {} },
  docenteEspacios: [],
  errorArray: [],
  fields: {},
  record: {
    nuevo: false,
    actualizado: false,
  },
  pagination: {},
};
