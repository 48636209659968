import Localidad from "@/apis/Localidad";

const actions = {
  async getLocalidades({ commit }, provincia_id) {
    try {
      const { status, data } = await Localidad.all(provincia_id);
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response.data);
    }
  },
  async getLocalidad({ commit }, id) {
    try {
      const { status, data } = await Localidad.view(id);
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response.data);
    }
  },
};
export default actions;
