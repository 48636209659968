const mutations = {
  setModels(state, models) {
    state.provincias = models.sort(function (a, b) {
      if (a.nombre < b.nombre) {
        return -1;
      } else {
        return 1;
      }
    });
    // console.log(state.provincias);
  },

  setModel(state, model) {
    state.provincia = model;
  },
};
export default mutations;
