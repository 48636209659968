import ActiveModel from "@/apis/ActiveModel";
import DocenteCursado from "@/apisNew/DocenteCursado";

const END_POINT = "new-docente-cursados";

const actions = {
  async getPlanilla({ commit }, bundle) {
    try {
      const { status, data } = await DocenteCursado.getPlanilla(bundle);
      if (status === 200) {
        // console.log("planilla exportada");
        const filename = "planillaCursados.xlsx";
        const url = URL.createObjectURL(
          new Blob([data], {
            type: "application/vnd.ms-excel",
            // type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        // commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getPlanillaDigital({ commit }, bundle) {
    try {
      const { status, data } = await DocenteCursado.getPlanillaDigital(bundle);
      if (status === 200) {
        console.log("bundle", bundle);
        // const filename = "planillaDigital.pdf";
        const filename = bundle.cursado + ".pdf";
        const url = URL.createObjectURL(
          new Blob([data], {
            type: "application/pdf",
            // type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        // commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getDocenteCursados({ commit }, bundle) {
    try {
      const { status, data } = await ActiveModel.all(END_POINT, bundle);
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async getDocenteCursado({ commit }, id) {
    try {
      const { status, data } = await ActiveModel.view(END_POINT, id);
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async addDocenteCursado({ commit }, item) {
    try {
      const { status, data } = await ActiveModel.store(END_POINT, item);
      if (status === 201) {
        console.log("registro creado");
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteDocenteCursado({ commit }, id) {
    try {
      const { status } = await ActiveModel.delete(END_POINT, id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateDocenteCursado({ commit }, model) {
    try {
      const { status, data } = await ActiveModel.update(END_POINT, model);
      if (status === 200) {
        commit("updateModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await ActiveModel.model(END_POINT);
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};
export default actions;
