import Vue from "vue";

const END_POINT = "new-docente-cursados";

export default {
  customQuery() {
    const params = {};
    return Vue.prototype.$axios.get(END_POINT + "/custom/query", { params });
  },
  getPlanilla(bundle) {
    const params = {
      ids: bundle.ids,
    };
    return Vue.prototype.$axios.post(END_POINT + "/custom/planilla", params, {
      responseType: "blob",
    });
  },
  getPlanillaDigital(bundle) {
    const params = {
      ids: bundle.ids,
    };
    return Vue.prototype.$axios.post(
      END_POINT + "/custom/planilladigital",
      params,
      {
        responseType: "blob",
      }
    );
  },
};
