const getters = {
  errors(state) {
    var merrors = {};
    for (var i in state.errorArray) {
      merrors[state.errorArray[i].field] = state.errorArray[i].message;
    }
    return merrors;
  },

  esIngresante(state) {
    return state.user.roles.includes("ingresante");
  },
};

export default getters;
