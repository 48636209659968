<template>
  <v-dialog ref="horarios" v-model="dialogHorarios" scrollable max-width="600">
    <v-card>
      <v-card-title>
        <v-btn link icon absolute top right @click="dialogHorarios = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <div>
          <horario-cursado-form
            ref="form"
            :id="id"
            :cursado_id="this.cursado_id"
            :cursado="this.cursado"
            @close="cerrar"
          >
            <template v-slot:cursado_id="{}">
              <v-text-field
                v-if="cursado.espacio"
                outlined
                disabled
                :value="cursado.espacio.searchTag"
              ></v-text-field>
            </template>
            <template v-slot:dia="{ model }">
              <v-select
                v-model="model.dia"
                outlined
                label="Ingresar día"
                :items="['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes']"
              ></v-select>
            </template>
            <template v-slot:hora_inicio="{ model }">
              <v-text-field
                type="time"
                outlined
                v-model="model.hora_inicio"
              ></v-text-field>
            </template>
            <template v-slot:hora_fin="{ model }">
              <v-text-field
                type="time"
                outlined
                v-model="model.hora_fin"
              ></v-text-field>
            </template>
          </horario-cursado-form>
          <v-card elevation="0">
            <v-card-title v-if="cursado.espacio"
              >Horarios de {{ cursado.espacio.searchTag }}</v-card-title
            >
            <v-card-text>
              <v-row>
                <v-col align="left">
                  <v-btn @click="newItem" color="primary">Nuevo</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Buscar por campo"
                    v-model="search.tag"
                    clearable
                    @click:clear="reload"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-data-table
                v-model="selectedItems"
                show-select
                :loading="loading.status"
                :items="horarioCursados"
                :headers="headers"
                :server-items-length="pagination.totalCount"
                :options.sync="options"
              >
                <template v-slot:body.prepend>
                  <tr>
                    <td></td>
                    <td v-for="(filterCol, i) in headers" :key="i">
                      <v-text-field
                        v-show="filterCol.filterable"
                        hide-details
                        filled
                        :label="'Buscar por ' + filterCol.text"
                        v-model="
                          like[
                            filterCol.table ? filterCol.table : filterCol.value
                          ]
                        "
                        clearable
                        @click:clear="reload"
                      ></v-text-field>
                    </td>
                  </tr>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-icon color="info" class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon color="info" @click="deleteItem(item.id)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="dialogHorarios = false">Cancelar</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import HorarioCursadoForm from "@/viewsNew/horario-cursado/HorarioCursadoForm";

export default {
  name: "HorarioCursadoABM",
  props: {
    cursado_id: [String, Number],
    cursado: Object,
  },
  components: { HorarioCursadoForm },
  data() {
    return {
      dialogHorarios: false,
      filters: {},
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      options: {
        joins: [],
      },
      expand: [],
    };
  },
  computed: {
    // ...mapState("NewCursadoModule",["cursado"]),
    ...mapState("NewHorarioCursadoModule", ["horarioCursados", "pagination"]),
    ...mapState(["search", "loading", "dialogs"]),

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        // {text:'Id',value:'id',filterable:true},
        { text: "Día", value: "dia", filterable: true },
        { text: "Hora Inicio", value: "hora_inicio", filterable: true },
        { text: "Hora Fin", value: "hora_fin", filterable: true },
        {
          text: "Acciones",
          value: "actions",
          filterable: false,
          nosearch: true,
        },
      ];
    },
  },
  watch: {
    dialogHorarios() {
      if (this.dialogHorarios) {
        this.reload();
      } else {
        this.$emit("close");
      }
    },
    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
        // this.reload();
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    // ...mapActions("NewCursadoModule",["getCursado"]),
    ...mapActions("NewHorarioCursadoModule", [
      "getHorarioCursados",
      "deleteHorarioCursado",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    newItem() {
      this.$refs.form.model = { cursado_id: this.cursado_id };
      this.$refs.form.dialog = true;
    },

    cerrar() {
      this.$refs.form.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.$refs.form.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteHorarioCursado(id);
      }
    },

    async reload() {
      this.filters.cursado_id = this.cursado.id;
      this.loading.status = true;
      await this.getHorarioCursados({
        like: this.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.clearFilters();
  },
  created() {
    // if(this.cursado_id){
    //   this.getCursado(this.cursado_id);
    // }
  },
};
</script>

<style scoped></style>
