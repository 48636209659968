import axios from "axios";
import authService from "@/services/auth.service";
import Vue from "vue";
import { Message } from "element-ui";

/** Default config for axios instance */
const API_ENDPOINT =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_HOST_DESARROLLO
    : process.env.VUE_APP_HOST_PRODUCCION;
let config = {
  baseURL: `${API_ENDPOINT}/v1/api/`,
};

let loader = {};
let loading = false;

/** Creating the instance for axios */
const httpClient = axios.create(config);

/** Auth token interceptors */
// const authInterceptor = config => {
//     config.headers.Authorization = `Bearer ${authService.getToken()}`;
//     loader = showLoader();
//     return config;
// };

// function hideLoader(){
//     loader.hide();
// }

function showLoader() {
  let loader = Vue.$loading.show({
    color: "#017BFF",
    loader: "dots",
    width: 128,
    height: 128,
    backgroundColor: "#ffffff",
    opacity: 0.5,
    zIndex: 999,
    canCancel: true,
    // onCancel: hideLoader(),
  });
  return loader;
}

httpClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${authService.getToken()}`;
  if (!loading) {
    loader = showLoader();
    loading = true;
  }
  return config;
});

/** Adding the response interceptors */
httpClient.interceptors.response.use(
  (response) => {
    /** TODO: Add any response interceptors */
    if (response.status === 201) {
      Message({
        type: "success",
        message: "registro creado correctamente",
      });
    }
    if (response.status === 204) {
      Message({
        type: "warning",
        message: "registro eliminado correctamente",
      });
    }
    loader.hide();
    loading = false;
    return response;
  },
  (error) => {
    // loading?loader.hide():'';
    if (error.response.status === 401) {
      window.location.href = "/login";
    } else {
      Vue.prototype.$dialog.error({
        text: error.response.data ? error.response.data : error,
        title: "Error",
      });
    }
    loader.hide();
    loading = false;

    loader.hide();
    loading = false;
    /** TODO: Do something with response error */
    return Promise.reject(error);
  }
  // loader.hide()
);

export { httpClient, API_ENDPOINT, config };
