const mutations = {
  setModels(state, models) {
    state.localidades = models.sort(function (a, b) {
      if (a.nombre < b.nombre) {
        return -1;
      } else {
        return 1;
      }
    });
  },

  setModel(state, model) {
    state.localidad = model;
  },
};
export default mutations;
