import Vue from "vue";

const END_POINT = "new-users";

export default {
  porRoles(END_POINT, bundle) {
    const params = {
      roles: bundle.roles,
      options: bundle.options,
      filters: bundle.filters,
      like: bundle.like,
      // ...bundle.filters,
      expand: bundle.expand,
      search: bundle.search,
      headers: bundle.headers,
    };
    return Vue.prototype.$axios.get(END_POINT + "/custom/porroles", { params });
  },

  getRoles() {
    return Vue.prototype.$axios.get(END_POINT + "/custom/getroles");
  },

  store(data) {
    return Vue.prototype.$axios.post(END_POINT + "/custom/createusuario", data);
  },

  update(item) {
    return Vue.prototype.$axios.put(
      `${END_POINT}/custom/updateusuario/${item.id}`,
      item
    );
  },

  customQuery() {
    const params = {};
    return Vue.prototype.$axios.get(END_POINT + "/custom/query", { params });
  },
};
