export default {
  user: { roles: [], coordinacion: [] },
  users: [],
  fields: {},
  errorArray: [],
  dialog: false,
  alumnos: [],
  docentes: [],
  defaultCarrera: null,
  total: null,
  pagination: {
    page: 1,
    perPage: 10,
  },
  avatarRaw: null,
  avatar: "https://placekitten.com/150/150",
  nuevo: false,
  actualizado: false,
  ingresantes: [],
  documentacion: {
    dni_frente: {},
    dni_dorso: {},
    cuil: {},
    formulario_inscripcion: {},
    formulario_medico: {},
  },
  key: 0,
};
