const mutations = {
  clearAlerts(state) {
    state.actualizado = false;
    state.nuevo = false;
  },

  showDialog(state) {
    state.dialog = true;
  },

  hideDialog(state) {
    state.dialog = false;
  },

  setModel(state, model) {
    state.errorArray = [];
    state.ingresante = model;
  },

  setModels(state, data) {
    state.ingresantes = data.models;
    state.pagination.totalCount = +data.headers["x-pagination-total-count"];
  },
  setCarrera(state, data) {
    state.ingresantesCarrera = data.models;
    state.pagination.totalCount = +data.headers["x-pagination-total-count"];
  },

  addModel(state, model) {
    state.ingresantes.unshift(model);
    state.nuevo = true;
  },

  updateModel(state, model) {
    state.ingresante = model;
    state.actualizado = true;
  },

  delModel(state, id) {
    const removeIndex = state.ingresantes
      .map(function (item) {
        return item.id;
      })
      .indexOf(id);
    state.ingresantes.splice(removeIndex, 1);
  },

  setErrors(state, errors) {
    state.errorArray = errors;
  },

  setFields(state, data) {
    state.fields = data.fields;
    state.emptyModel = data.model;
  },
};

export default mutations;
