import Vue from "vue";
import { httpClient } from "@/apis/Api";

const END_POINT = "new-cursados";

export default {
  activas(bundle) {
    const params = {
      options: bundle.options,
      filters: bundle.filters,
      like: bundle.like,
      expand: bundle.expand,
      search: bundle.search,
      headers: bundle.headers,
    };
    // console.log('bundle',bundle);
    // console.log('params',params);
    return Vue.prototype.$axios.get(END_POINT + "/custom/activas", { params });
  },

  crearCicloLectivo(data) {
    return httpClient.post(END_POINT + "/custom/crearciclolectivo", data);
  },
  dividirComision(data) {
    console.log("dividir comision", data);
    return httpClient.post(END_POINT + "/custom/dividircomision", data);
  },

  customQuery() {
    const params = {};
    return Vue.prototype.$axios.get(END_POINT + "/custom/query", { params });
  },
};
