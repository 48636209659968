import { httpClient } from "./Api";

const END_POINT = "alumno-cursados";

export default {
  deleteAll(items) {
    return httpClient.delete(END_POINT + "/custom/deleteall", {
      data: { items: items },
    });
  },

  porCurso(bundle) {
    const params = {
      expand: [].toString(),
      filters: bundle.filters,
      options: bundle.options,
    };
    return httpClient.get(END_POINT, { params });
  },

  all() {
    const params = {
      expand: ["cursado", "carrera", "materia", "espacio"].toString(),
    };
    return httpClient.get(END_POINT, { params });
  },

  sendMailNota(items) {
    return httpClient.post(`${END_POINT}/custom/sendmailnota`, items);
  },

  addBatch(items) {
    return httpClient.post(`${END_POINT}/custom/batchcreate`, items);
  },

  batchUpdate(items) {
    return httpClient.post(`${END_POINT}/custom/batchupdate`, items);
  },

  store(item) {
    return httpClient.post(`${END_POINT}`, item);
  },

  delete(id) {
    return httpClient.delete(`${END_POINT}/${id}`);
  },

  update(item) {
    return httpClient.put(`${END_POINT}/${item.id}`, item);
  },
  view(id) {
    const params = {
      expand: [].toString(),
    };
    return httpClient.get(`${END_POINT}/${id}`, { params });
  },
  model() {
    return httpClient.get(`${END_POINT}/model`);
  },
};
