<template>
  <div>
    <docente-programa-examen-form ref="form" :id="id" @close="cerrar">
    </docente-programa-examen-form>
    <periodo-examen-form ref="periodoform" @close="cerrarPeriodo">
    </periodo-examen-form>
    <v-card>
      <v-card-title>ProgramaExamens</v-card-title>
      <v-card-text>
        <v-row>
          <v-col align="left">
            <v-btn @click="newItem" color="primary">Nuevo Programa</v-btn>
          </v-col>
          <!--          <v-col align="left">-->
          <!--            <v-btn @click="newPeriodo" color="secondary">Nuevo Periodo</v-btn>-->
          <!--          </v-col>-->
          <!--          <v-col align="left">-->
          <!--            <v-btn href="/periodo-examenes" color="warning">Periodos</v-btn>-->
          <!--          </v-col>-->
        </v-row>
        <!--        <v-row>-->
        <!--          <v-col>-->
        <!--            <v-text-field-->
        <!--              label="Buscar por campo"-->
        <!--              v-model="search.tag"-->
        <!--              clearable-->
        <!--              @click:clear="reload"-->
        <!--            ></v-text-field>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
        <v-data-table
          v-model="selectedItems"
          show-select
          :loading="loading.status"
          :items="programaExamens"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row ref="filterRow" :headers="headers" @reload="reload">
              <template v-slot:materia.nombre="{}">
                <v-autocomplete
                  clearable
                  hide-details
                  outlined
                  dense
                  multiple
                  :items="uCurricularCarreras"
                  v-model="filters['materia_id']"
                  item-value="id"
                  item-text="searchTag"
                ></v-autocomplete>
              </template>
              <template v-slot:carrera.codigo="{}">
                <v-select
                  outlined
                  dense
                  hide-details
                  clearable
                  @change="reload"
                  v-model="filters['carrera.codigo']"
                  :items="codigos"
                ></v-select>
              </template>
              <template v-slot:periodoExamen.nombre="{}">
                <v-select
                  outlined
                  dense
                  hide-details
                  clearable
                  @change="reload"
                  v-model="filters['periodo_examen_id']"
                  :items="periodoExamens"
                  item-text="nombre"
                  item-value="id"
                ></v-select>
              </template>
            </filter-row>
          </template>

          <!--          <template v-slot:item.estado="{ item }">-->
          <!--            <v-switch-->
          <!--                label="Publicado"-->
          <!--                dense-->
          <!--                v-model="item.estado"-->
          <!--                true-value="PUBLICADO"-->
          <!--                false-value="BORRADOR"-->
          <!--                @change="publicar(item)"-->

          <!--            ></v-switch>-->
          <!--          </template>-->

          <template v-slot:item.archivo="{ item }">
            <v-btn color="success" link :href="downloadPdf(item)"
              >Descargar</v-btn
            >
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="item.estado !== 'PUBLICADO'"
              color="info"
              @click="deleteItem(item.id)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FilterRow from "@/viewsNew/utils/FilterRow";
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import PeriodoExamenForm from "@/viewsNew/periodo-examen/PeriodoExamenForm.vue";
import DocenteProgramaExamenForm from "@/flavor/docente/views/programa-examen/DocenteProgramaExamenForm.vue";

export default {
  name: "DocenteProgramaExamenABM",
  components: { DocenteProgramaExamenForm, FilterRow, PeriodoExamenForm },
  data() {
    return {
      filters: {},
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      options: {
        joins: ["periodoExamen", "carrera"],
      },
      expand: ["carrera", "materia", "periodoExamen"],
    };
  },
  computed: {
    ...mapState("UserModule", ["user"]),
    ...mapGetters("CarreraModule", ["codigos"]),
    ...mapState("NewUCurricularCarreraModule", ["uCurricularCarreras"]),
    ...mapState("ProgramaExamenModule", ["programaExamens", "pagination"]),
    ...mapState("PeriodoExamenModule", ["periodoExamens"]),
    ...mapState(["search", "loading", "dialogs"]),

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        // {text:'Id',value:'id',filterable:true},
        { text: "Carrera", value: "carrera.codigo", filterable: false },
        {
          text: "Materia",
          value: "materia.nombre",
          filterable: false,
          table: "u_curricular_carrera.nombre",
        },
        // { text: "periodo", value: "periodoExamen.nombre", filterable: true },
        {
          text: "Año",
          value: "anio",
          filterable: true,
          table: "programa_examen.anio",
        },
        { text: "Cutrimestre", value: "cuatrimestre", filterable: true },
        { text: "Tipo", value: "condicion", filterable: true },
        { text: "Estado", value: "estado", filterable: true },
        { text: "Archivo", value: "archivo", filterable: true },

        {
          text: "Acciones",
          value: "actions",
          filterable: false,
          nosearch: true,
        },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("PeriodoExamenModule", ["getPeriodoExamens"]),
    ...mapActions("NewUCurricularCarreraModule", ["getUCurricularCarreras"]),
    ...mapActions("ProgramaExamenModule", [
      "getProgramaExamenPdf",
      "getProgramaExamens",
      "deleteProgramaExamen",
      "updateProgramaExamen",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    publicar(item) {
      this.updateProgramaExamen(item);
      this.reload();
    },

    downloadPdf(item) {
      if (item.archivo_examen) {
        return item.archivo_examen;
      }
      const API_ENDPOINT =
        process.env.NODE_ENV === "development"
          ? process.env.VUE_APP_HOST_DESARROLLO
          : process.env.VUE_APP_HOST_PRODUCCION;
      const url = `${API_ENDPOINT}/v1/api/programa-examen/custom/download?id=${item.id}`;
      return url;
      // window.location.href = url;
    },

    newItem() {
      this.$refs.form.model = { carrera: {} };
      this.$refs.form.dialog = true;
    },
    newPeriodo() {
      // this.$refs.periodoform.model = {};
      this.$refs.periodoform.dialog = true;
    },
    verPeriodos() {},

    cerrar() {
      this.$refs.form.dialog = false;
      this.reload();
    },

    cerrarPeriodo() {
      this.$refs.periodoform.dialog = false;
      // this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.$refs.form.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteProgramaExamen(id);
      }
    },

    async reload() {
      this.loading.status = true;
      await this.getProgramaExamens({
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: { created_by: this.user.id, ...this.filters },
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.clearFilters();
  },
  created() {
    this.getPeriodoExamens({});
    this.getCarreras();
    this.getUCurricularCarreras({
      options: { itemsPerPage: -1 },
    });
  },
};
</script>

<style scoped></style>
