<template>
  <div>
    <!--    <docente-materia-form ref="form" @close="cerrar"></docente-materia-form>-->

    <v-dialog max-width="800px" v-model="recordatorio" scrollable>
      <template v-slot:default>
        <v-card max-width="800" class="align-center">
          <v-card-title>
            Por Favor Completar la Encuesta de cada Espacio antes del 6 de
            Diciembre
          </v-card-title>
          <v-card-actions>
            <v-btn @click="cerrarRecordatorio" color="secondary">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog max-width="800px" v-model="encuesta" scrollable fullscreen>
      <template v-slot:default>
        <v-card max-width="800" class="align-center">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSexis0fbOxzhgdPoI6dXGF_y8pkki-udWyH3ZOC2GW-D73sHw/viewform?embedded=true"
            width="100%"
            height="1051"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            >Cargando…</iframe
          >
          <v-card-actions>
            <v-btn @click="cerrarEncuesta" color="secondary">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-card>
      <v-card-title> Comisiones </v-card-title>
      <v-card-text>
        <div>
          <!--          <div>-->
          <!--            <v-btn color="success" @click="getPlanillas">Planillas</v-btn>-->
          <!--          </div>-->
        </div>
        <!--        <v-divider></v-divider>-->
        <v-text-field
          label="Buscar por campo"
          v-model="search.tag"
          clearable
          @click:clear="reload"
        ></v-text-field>
        <v-row>
          <v-col>
            <v-data-iterator :items="filteredDocentesCursados">
              <template v-slot:default="{ items }">
                <v-row>
                  <v-col
                    v-for="item in items"
                    cols="12"
                    md="6"
                    lg="4"
                    :key="item.id"
                  >
                    <v-card dark color="primary">
                      <v-card-title>
                        <div>
                          <div>
                            {{ item.espacio.nombre }}
                          </div>
                          <div class="subtitle-2">
                            {{ item.carrera.nombre }}
                          </div>

                          <div class="subtitle-1">
                            Año Lectivo: {{ item.anio_lectivo }}
                          </div>
                          <div class="subtitle-1">
                            Comisión: {{ item.comision }}
                          </div>
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <div class="subtitle-2">
                          {{ item.horariosFormateados.corto }}
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <div class="w-100">
                          <v-btn class="m-1" block :href="verAlumnos(item)"
                            >Alumnos</v-btn
                          >
                          <v-btn
                            :href="planillaDigital(item.id)"
                            link
                            class="m-1"
                            block
                            color="success"
                            >Planilla Digital</v-btn
                          >
                          <v-btn
                            v-if="esFormacionDocente(item)"
                            @click="abrirEncuesta"
                            class="m-1"
                            block
                            color="deep-purple"
                            >Realizar Encuesta</v-btn
                          >
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-col>
        </v-row>

        <!--        <v-row v-for="(porCarrera, codigo) in porCarreras" :key="codigo">-->
        <!--          <v-col>-->
        <!--            <div class="title teal text-light p-3">-->
        <!--              {{ porCarrera.carrera.nombre }}-->
        <!--            </div>-->
        <!--            <v-row v-for="(materias, anio) in porCarrera.anios" :key="anio">-->
        <!--              <v-col>-->
        <!--                <span class="title">Materias de {{ anio }}° Año</span>-->
        <!--              </v-col>-->
        <!--            </v-row>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
// import DocenteMateriaForm from "@/flavor/docente/views/materias/DocenteMateriaForm";

export default {
  name: "DocenteCursadosList",
  // components: { DocenteMateriaForm },
  data() {
    return {
      carrera_id: null,
      search: {},
      encuesta: false,
      recordatorio: true,
    };
  },
  computed: {
    // ...mapGetters("NewDocenteEspacio",["porCarreras"]),
    ...mapState("DocenteCursadoModule", ["docenteCursados"]),
    ...mapState("UserModule", ["user"]),
    ...mapState("CarreraModule", ["carreras"]),

    filteredDocentesCursados() {
      const fechaActual = new Date();
      const anioActual = fechaActual.getFullYear();
      // const anioActualFormatoYYYY = anioActual.toString();
      const docenteCursado = this.docenteCursados.filter(
        (el) => el.cursado.anio_lectivo == anioActual
      );
      const materias = docenteCursado.map((el) => el.cursado);
      const materiasFiltradas = materias.filter((materia) => {
        // normalizamos y pasamos a minúsculas ambas cadenas
        if (this.search.tag) {
          const searchNorm = this.search.tag
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
          const materiaNorm = materia.espacio.nombre
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
          return materiaNorm.includes(searchNorm);
        } else {
          return true;
        }
      });

      return materiasFiltradas;
    },

    // porCarreras() {
    //   const carreras = this.filteredDocentesCursados.reduce((carreras, item) => {
    //     console.log(item);
    //     let tag = item.cursado.espacio.anio;
    //     let tag2 = item.carrera.codigo;
    //
    //     carreras[tag2] = carreras[tag2] || {};
    //     carreras[tag2].carrera = item.carrera;
    //     carreras[tag2].anios = carreras[tag2].anios || {};
    //
    //     carreras[tag2].anios[tag] = carreras[tag2].anios[tag] || [];
    //
    //     carreras[tag2].anios[tag].push(item.cursado);
    //     return carreras;
    //   }, {});
    //   return carreras;
    // },
  },
  watch: {
    carrera_id() {
      this.reload();
    },
  },
  methods: {
    ...mapActions("DocenteCursadoModule", [
      "getDocenteCursados",
      "getPlanilla",
      // "deleteDocenteCursado",
    ]),
    ...mapActions("CarreraModule", ["getCarreras"]),

    cerrarEncuesta() {
      this.encuesta = false;
    },
    cerrarRecordatorio() {
      this.recordatorio = false;
    },

    abrirEncuesta() {
      this.encuesta = true;
    },

    esFormacionDocente(item) {
      if (["PEI", "PEP", "PESB"].includes(item.carrera.codigo)) {
        return true;
      }
      return false;
    },

    planillaDigital(id) {
      return "/planilla-digital-cursado/" + id;
    },

    async borrarItem(item) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteDocenteEspacio(item.id);
      }
    },
    reload() {
      this.getDocenteCursados({
        options: {
          // joins: ["carrera"],
          sortBy: ["id"],
          sortDesc: [false],
          joins: ["cursado"],
        },

        filters: {
          docente_id: this.user.id,
          carrera_id: this.carrera_id,
          cursado_historico: 0,
        },
        expand: ["espacio", "carrera"].toString(),
      });
    },
    cerrar() {
      this.reload();
    },
    abrirForm() {
      this.$refs.form.dialog = true;
    },
    verAlumnos(item) {
      return "/docente/alumno-cursado/" + item.id;
      // this.$router.push("/docente/alumno-cursado/" + item.id);
    },
    getPlanillas() {
      const ids = this.docenteCursados.map((el) => el.cursado_id);
      this.getPlanilla({ ids: ids });
    },
  },
  created() {
    this.reload();
    this.getCarreras();
  },
};
</script>

<style scoped></style>
