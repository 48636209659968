import ActiveModel from "@/apis/ActiveModel";
import NotaAlumnoCursado from "@/apisNew/NotaAlumnoCursado";

const END_POINT = "nota-alumno-cursados";

const actions = {
  async notificarme({ commit }, id) {
    const { status, data } = await NotaAlumnoCursado.notificarme(id);
    if (status === 200) {
      commit("setModel", data);
    }
  },

  async getNotaAlumnoCursadosAprobados({ commit }, bundle) {
    try {
      const { status, data } = await NotaAlumnoCursado.getAprobados(bundle);
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteFichaAcademica({ commit }, id) {
    const { status, data } = await NotaAlumnoCursado.deleteFicha(id);
    if (status === 200) {
      // console.log("registro creado");
      commit("delModel", data);
    }
  },

  async updateFichaAcademica({ commit }, model) {
    const { status, data } = await NotaAlumnoCursado.updateFicha(model);
    if (status === 200) {
      // console.log("registro creado");
      commit("updateModel", data);
    }
  },

  async addFichaAcademica({ commit }, model) {
    const { status, data } = await NotaAlumnoCursado.guardarFicha(model);
    if (status === 200) {
      console.log("registro creado");
      commit("addModel", data);
    }
  },

  async getNotaAlumnoCursados({ commit }, bundle) {
    try {
      const { status, data } = await ActiveModel.all(END_POINT, bundle);
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async getNotaAlumnoCursado({ commit }, id) {
    try {
      const { status, data } = await ActiveModel.view(END_POINT, id);
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getNotaAlumnoCursadoWithParams({ commit }, bundle) {
    try {
      const { status, data } = await ActiveModel.viewWithParams(
        END_POINT,
        bundle.id,
        bundle
      );
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async addNotaAlumnoCursado({ commit }, item) {
    try {
      const { status, data } = await ActiveModel.store(END_POINT, item);
      if (status === 201) {
        console.log("registro creado");
        commit("addModel", data);
        return data;
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteNotaAlumnoCursado({ commit }, id) {
    try {
      const { status } = await ActiveModel.delete(END_POINT, id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateNotaAlumnoCursado({ commit }, model) {
    try {
      const { status, data } = await ActiveModel.update(END_POINT, model);
      if (status === 200) {
        commit("updateModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await ActiveModel.model(END_POINT);
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};
export default actions;
