import { httpClient } from "./Api";

const END_POINT = "cursados";

export default {
  getCiclosLectivos() {
    const params = {};
    return httpClient.get(END_POINT + "/custom/ciclos", { params });
  },

  vigentesPorAlumno(options) {
    const params = {
      expand: [
        "docentes",
        "carrera",
        "espacio",
        "horarios",
        "docenteCursados",
        "notaAlumno",
        "nombreMateria",
        "correlativas",
      ].toString(),
      carrera_id: options.carrera_id,
      anio: options.anio,
      campo_id: options.campo_id,
    };
    return httpClient.get(END_POINT + "/custom/vigente", { params });
  },

  porAlumno() {
    const params = {
      expand: [
        "docentes",
        "carrera",
        "espacio",
        "horarios",
        "docenteCursados",
        "notaAlumno",
        "nombreMateria",
      ].toString(),
    };
    return httpClient.get(END_POINT + "/custom/poralumno", { params });
  },

  porDocente(bundle) {
    const params = {
      expand: [
        "docentes",
        "carrera",
        "espacio",
        "horarios",
        "docenteCursados",
        "nombreMateria",
      ].toString(),
      filters: bundle.filters,
      options: bundle.options,
    };
    return httpClient.get(END_POINT + "/custom/pordocente", { params });
  },

  all(bundle) {
    const params = {
      expand: [
        "matriculados",
        "docentes",
        "carrera",
        "espacio",
        "horarios",
        // "docenteCursados",
        // "nombreMateria",
      ].toString(),
      // bundle:bundle,
      // search: bundle.search,
      // itemsPerPage: bundle.options.itemsPerPage,
      // page: bundle.options.page,
      // carrera:{id: bundle.options.carrera_id},
      // anio_lectivo: bundle.options.anio_lectivo,
      // espacio: {anio: bundle.options.anio},
      options: bundle.options,
      filters: bundle.filters,
    };
    return httpClient.get(END_POINT, { params });
  },

  deleteAll(items) {
    return httpClient.delete(END_POINT + "/custom/deleteall", {
      data: { items: items },
    });
  },

  store(data) {
    return httpClient.post(END_POINT, data);
  },
  crearCicloLectivo(data) {
    return httpClient.post(END_POINT + "/custom/crearciclolectivo", data);
  },

  delete(id) {
    return httpClient.delete(`${END_POINT}/${id}`);
  },

  update(item) {
    return httpClient.put(`${END_POINT}/${item.id}`, item);
  },
  view(id) {
    const params = {
      expand: [
        "materia",
        "espacio",
        "nombreMateria",
        "docentes",
        "horarios",
      ].toString(),
    };
    return httpClient.get(`${END_POINT}/${id}`, { params });
  },
  model() {
    return httpClient.get(`${END_POINT}/model`);
  },
};
