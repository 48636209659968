import { httpClient } from "./Api";

const END_POINT = "alumno-carreras";

export default {
  sendMailTurno(id) {
    const params = {
      id: id,
    };
    return httpClient.get(END_POINT + "/custom/sendmailturno", {
      params: params,
    });
  },
  sendMailPreinscripcion(id) {
    const params = {
      id: id,
    };
    return httpClient.get(END_POINT + "/custom/sendmailpreinscripcion", {
      params: params,
    });
  },

  sendMailConfirmacion(id) {
    const params = {
      id: id,
    };
    return httpClient.get(END_POINT + "/custom/sendmailconfirmacion", {
      params: params,
    });
  },

  uploadDocumentacion(data) {
    return httpClient.post(END_POINT + "/custom/uploaddocumentacion", data);
  },

  getDocumentacion(bundle) {
    // console.log(bundle);
    const params = {
      ...bundle,
      field: bundle.field,
    };
    return httpClient.get(END_POINT + "/custom/getdocumentacion", {
      params: params,
      responseType: "blob",
    });
  },
  deleteDocumentacion(bundle) {
    // console.log(bundle);
    const params = {
      ...bundle,
      field: bundle.field,
    };
    return httpClient.get(END_POINT + "/custom/deletedocumentacion", {
      params: params,
    });
  },

  porAlumno(bundle) {
    if (!bundle.filters.alumno_id) {
      // console.log(bundle);
      return [];
    }
    const params = {
      expand: ["alumno", "carrera", "turno"].toString(),
      filters: bundle.filters,
      options: bundle.options,
    };
    return httpClient.get(END_POINT, { params });
  },
  porCarrera(bundle) {
    const params = {
      expand: ["alumno"].toString(),
      filters: bundle.filters,
      options: bundle.options,
    };
    return httpClient.get(END_POINT, { params });
  },

  all(bundle) {
    const params = {
      expand: [...(bundle.expand ? bundle.expand : "")].toString(),
      filters: bundle.filters,
      options: bundle.options,
    };
    return httpClient.get(END_POINT, { params });
  },

  store(data) {
    return httpClient.post(END_POINT, data);
  },

  delete(id) {
    return httpClient.delete(`${END_POINT}/${id}`);
  },

  update(item) {
    return httpClient.put(`${END_POINT}/${item.id}`, item);
  },
  view(bundle) {
    const params = {
      expand: [...(bundle.expand ? bundle.expand : "")].toString(),
    };
    return httpClient.get(`${END_POINT}/${bundle.id}`, { params });
  },
  model() {
    return httpClient.get(`${END_POINT}/model`);
  },
};
