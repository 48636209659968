var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{ref:"docentes",attrs:{"eager":"","scrollable":"","max-width":"600"},model:{value:(_vm.dialogDocentes),callback:function ($$v) {_vm.dialogDocentes=$$v},expression:"dialogDocentes"}},[_c('docente-examen-form',{ref:"form",attrs:{"id":_vm.id,"examen_id":_vm.examen.id},on:{"close":_vm.cerrar},scopedSlots:_vm._u([{key:"rol",fn:function(ref){
var model = ref.model;
return [_c('v-autocomplete',{attrs:{"label":"seleccione Rol","items":[
            { label: 'presidente', value: 0 },
            { label: 'vocal', value: 1 },
            { label: 'veedor', value: 2 } ],"item-text":"label","item-value":"value"},model:{value:(model.rol),callback:function ($$v) {_vm.$set(model, "rol", $$v)},expression:"model.rol"}})]}},{key:"docente_id",fn:function(ref){
          var model = ref.model;
return [_c('v-autocomplete',{attrs:{"label":"seleccione Docente","items":model.rol === 2 ? _vm.estudiantes : _vm.docentes,"item-text":"searchTag","item-value":"id"},model:{value:(model.docente_id),callback:function ($$v) {_vm.$set(model, "docente_id", $$v)},expression:"model.docente_id"}})]}}])}),_c('v-card',[(_vm.examen.materia)?_c('v-card-title',[_vm._v(" Docentes de "+_vm._s(_vm.examen.materia.searchTag)+" "),_c('v-btn',{attrs:{"link":"","icon":"","absolute":"","top":"","right":""},on:{"click":function($event){_vm.dialogDocentes = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"align":"left"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.newItem}},[_vm._v("Nuevo")])],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar por campo","clearable":""},on:{"click:clear":_vm.reload},model:{value:(_vm.search.tag),callback:function ($$v) {_vm.$set(_vm.search, "tag", $$v)},expression:"search.tag"}})],1)],1),_c('v-data-table',{attrs:{"show-select":"","loading":_vm.loading.status,"items":_vm.docenteExamens,"headers":_vm.headers,"server-items-length":_vm.pagination.totalCount,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('filter-row',{ref:"filterRow",attrs:{"headers":_vm.headers},on:{"reload":_vm.reload}})]},proxy:true},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")])]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.dialogDocentes = false}}},[_vm._v("Cerrar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }