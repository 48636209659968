const mutations = {
  setRoles(state, data) {
    state.roles = data;
  },

  setModels(state, data) {
    // console.log(data);
    state.users = data.items;
    state.pagination = data._meta;
  },

  setModel(state, data) {
    state.user = data;
  },

  addModel(state, model) {
    state.record.nuevo = true;
    state.users.unshift(model);
  },

  updateModel(state, model) {
    state.model = model;
    state.record.actualizado = true;
  },

  delModel(state, id) {
    const removeIndex = state.users
      .map(function (item) {
        return item.id;
      })
      .indexOf(id);
    state.users.splice(removeIndex, 1);
  },

  setErrors(state, errors) {
    state.errorArray = errors;
  },

  setFields(state, data) {
    state.fields = data.fields;
    state.emptyModel = data.model;
  },
};
export default mutations;
