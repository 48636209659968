import ActiveModel from "@/apis/ActiveModel";

const END_POINT = "corte-inscripcion-carreras";

const actions = {
  async getCorteInscripcionCarreraActivo({ commit }, bundle) {
    try {
      const { status, data } = await ActiveModel.all(END_POINT, bundle);
      if (status === 200) {
        commit("setModel", data.items[0] ? data.items[0] : {});
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response.data);
    }
  },
  async getCorteInscripcionCarreras({ commit }, bundle) {
    try {
      const { status, data } = await ActiveModel.all(END_POINT, bundle);
      if (status === 200) {
        commit("setModels", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response.data);
    }
  },
  async getCorteInscripcionCarrera({ commit }, id) {
    try {
      const { status, data } = await ActiveModel.view(END_POINT, id);
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response.data);
    }
  },
  async getCorteInscripcionCarreraWithParams({ commit }, bundle) {
    try {
      const { status, data } = await ActiveModel.viewWithParams(
        END_POINT,
        bundle.id,
        bundle
      );
      if (status === 200) {
        commit("setModel", data);
        return data;
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response.data);
    }
  },

  async addCorteInscripcionCarrera({ commit }, item) {
    try {
      const { status, data } = await ActiveModel.store(END_POINT, item);
      if (status === 201) {
        console.log("registro creado");
        commit("addModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteCorteInscripcionCarrera({ commit }, id) {
    try {
      const { status } = await ActiveModel.delete(END_POINT, id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateCorteInscripcionCarrera({ commit }, model) {
    try {
      const { status, data } = await ActiveModel.update(END_POINT, model);
      if (status === 200) {
        commit("updateModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await ActiveModel.model(END_POINT);
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};
export default actions;
