export default {
  examen: { carrera: {}, materia: {}, docentes: [], espacio: {} },
  examens: [],
  errorArray: [],
  fields: {},
  record: {
    nuevo: false,
    actualizado: false,
  },
  pagination: {},
};
