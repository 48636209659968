import { httpClient } from "./Api";

const END_POINT = "provincia";

export default {
  all() {
    const params = {
      expand: [].toString(),
    };
    return httpClient.get(END_POINT, { params });
  },

  view(id) {
    const params = {
      expand: [].toString(),
    };
    return httpClient.get(END_POINT + "/" + id, { params });
  },
};
