<template>
  <div>
    <docente-examen-a-b-m @close="reload" ref="docentes" :examen="examen" />

    <!--    <bulk-examen-form @close="cerrar" ref="bulk"></bulk-examen-form>-->

    <!--    <v-dialog eager v-model="dialog" max-width="600">-->
    <!--      <examen-form ref="form" :id="id" @close="cerrar"> </examen-form>-->
    <!--    </v-dialog>-->
    <v-card>
      <v-card-title>Examenes</v-card-title>
      <v-card-text>
        <div class="d-flex align-content-center">
          <!--          <div class="">-->
          <!--            <v-btn class="" @click="newItem" color="primary">Nuevo</v-btn>-->
          <!--          </div>-->
          <!--          <div class="mx-3">-->
          <!--            <v-btn class="" @click="bulkNew" color="success">Masivo</v-btn>-->
          <!--          </div>-->
          <div class="mx-5">
            <v-switch
              class="mt-0 pt-0"
              v-model="options.vencidos"
              label="Vencidos"
            ></v-switch>
          </div>
          <v-spacer></v-spacer>
          <!--          <div class="flex d-flex">-->
          <!--            <v-select-->
          <!--              dense-->
          <!--              outlined-->
          <!--              hide-details-->
          <!--              :items="acciones"-->
          <!--              v-model="accion"-->
          <!--            ></v-select>-->
          <!--            <v-btn color="primary" @click="ejecutarAccion">Ejecutar</v-btn>-->
          <!--          </div>-->
        </div>
        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          show-select
          v-model="selectedItems"
          :loading="loading.status"
          :items="examens"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row ref="filterRow" :headers="headers" @reload="reload">
              <template v-slot:u_curricular_carrera.nombre="{}">
                <v-autocomplete
                  clearable
                  hide-details
                  outlined
                  dense
                  multiple
                  :items="uCurricularCarreras"
                  v-model="filters['materia_id']"
                  item-value="id"
                  item-text="searchTagSinAcento"
                ></v-autocomplete>
              </template>

              <template v-slot:carrera.codigo="{}">
                <v-select
                  multiple
                  outlined
                  dense
                  hide-details
                  clearable
                  @change="reload"
                  v-model="filters['carrera.codigo']"
                  :items="codigos"
                ></v-select>
              </template>
            </filter-row>
          </template>

          <template v-slot:item.docente_id="{ item }">
            <v-card
              dark
              color="primary"
              class="p-2"
              @click="abrirDocentes(item)"
            >
              <div v-for="docente in item.docentes" :key="docente.id">
                {{ docente.apellido }}
              </div>
            </v-card>
          </template>

          <!--          <template v-slot:item.actions="{ item }">-->
          <!--            <v-icon color="info" class="mr-2" @click="editItem(item)">-->
          <!--              mdi-pencil-->
          <!--            </v-icon>-->
          <!--            <v-icon color="info" @click="deleteItem(item.id)">-->
          <!--              mdi-delete-->
          <!--            </v-icon>-->
          <!--          </template>-->
          <!--          <template v-slot:item.alumnos="{ item }">-->
          <!--            <v-icon-->
          <!--              color="info"-->
          <!--              @click="$router.push('alumno-examen/' + item.id)"-->
          <!--            >-->
          <!--              mdi-account-->
          <!--            </v-icon>-->
          <!--          </template>-->
          <template v-slot:item.estado="{ item }">
            <v-chip :color="item.estado ? 'success' : 'error'">{{
              item.estado ? "Activo" : "Suspendido"
            }}</v-chip>
          </template>
          <!--          <template v-slot:item.planilla="{ item }">-->
          <!--            <div>-->
          <!--              <v-btn-->
          <!--                target="_blank"-->
          <!--                :to="'/planilla-digital-examen/' + item.id"-->
          <!--                link-->
          <!--                @click="verPlanilla(item)"-->
          <!--                >Planilla</v-btn-->
          <!--              >-->
          <!--            </div>-->
          <!--          </template>-->
          <template v-slot:item.fecha="{ item }">
            {{ item.date }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
// import ExamenForm from "@/viewsNew/examen/ExamenForm";
import FilterRow from "@/viewsNew/utils/FilterRow";
import DocenteExamenABM from "@/viewsNew/docente-examen/DocenteExamenABM";
// import BulkExamenForm from "@/viewsNew/examen/BulkExamenForm";

export default {
  name: "CoordinacionExamenABM",
  components: {
    // BulkExamenForm,
    DocenteExamenABM,
    FilterRow,
    // ExamenForm
  },
  data() {
    return {
      vencidos: false,
      examen: {},
      acciones: [
        { value: 1, text: "Activar" },
        { value: 2, text: "Desactivar" },
      ],
      accion: null,
      selectedItems: [],
      dialog: false,
      id: null,
      options: {
        // itemsPerPage: -1,
        joins: ["carrera"],
      },
      expand: [
        "materia",
        "carrera",
        "u_curricular_carrera",
        "cantAlumnos",
        "docentes",
      ],
    };
  },
  computed: {
    ...mapState("UserModule", ["user"]),
    ...mapState("NewUCurricularCarreraModule", ["uCurricularCarreras"]),
    ...mapGetters("CarreraModule", ["codigos"]),
    // ...mapGetters("ExamenModule", ["fechaSola","hora"]),
    ...mapState("ExamenModule", ["examens", "pagination"]),
    ...mapState(["search", "filters", "loading", "dialogs"]),

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        { text: "Id", value: "id", filterable: true, table: "examen.id" },
        { text: "Turno", value: "turno", filterable: true },
        { text: "Estado", value: "estado", filterable: true },
        {
          text: "Fecha",
          value: "fecha",
          filterable: true,
          table: "examen.fecha",
        },
        {
          text: "Hora",
          value: "hora",
          filterable: true,
          table: "examen.fecha",
        },
        { text: "Carrera", value: "carrera.codigo", filterable: false },

        {
          text: "Materia",
          value: "u_curricular_carrera.nombre",
          filterable: false,
          table: "u_curricular_carrera.nombre",
        },
        {
          text: "Inicio Inscripcion",
          value: "fecha_inicio_inscripcion",
          filterable: true,
        },
        {
          text: "Fin Inscripcion",
          value: "fecha_fin_inscripcion",
          filterable: true,
        },
        // { text: "Docentes", value: "docentes" ,filterable:true},
        // {text:'Inscripción',value:'inscripcion'},
        // { text: "Alumnos", value: "alumnos" ,filterable:true},

        {
          text: "Cant Alumnos",
          value: "cantAlumnos",
          filterable: false,
          nosearch: true,
        },
        {
          text: "docentes",
          value: "docente_id",
          filterable: true,
          nosearch: true,
        },
        // {
        //   text: "Alumnos",
        //   value: "alumnos",
        //   filterable: false,
        //   nosearch: true,
        // },
        // {
        //   text: "Planilla",
        //   value: "planilla",
        //   filterable: false,
        //   nosearch: true,
        // },
        // {
        //   text: "Acciones",
        //   value: "actions",
        //   filterable: false,
        //   nosearch: true,
        // },
      ];
    },
  },
  watch: {
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("NewUCurricularCarreraModule", ["getUCurricularCarreras"]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("ExamenModule", [
      "getExamens",
      "deleteExamen",
      "updateExamen",
      "get",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    obtenerCoordinaciones() {
      return this.user.coordinacion.map((el) => el.codigo);
    },

    ejecutarAccion() {
      switch (this.accion) {
        case 1:
          this.cambiarEstado(1);
          break;
        case 2:
          this.cambiarEstado(0);
          break;
        default:
          return;
      }
    },

    cambiarEstado(flag) {
      for (var i in this.selectedItems) {
        let model = this.selectedItems[i];
        model.estado = flag;
        this.updateExamen(model);
      }
      this.selectedItems = [];
    },

    bulkNew() {
      this.$refs.bulk.dialog = true;
    },

    newItem() {
      this.$refs.form.model = { carrera: {} };
      this.$refs.form.id = null;
      this.dialog = true;
    },

    cerrar() {
      this.dialog = false;
      this.reload();
    },

    editItem(item) {
      console.log(item);
      this.$refs.form.id = item.id;
      this.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteExamen(id);
      }
    },

    async reload() {
      this.loading.status = true;
      await this.getExamens({
        like: this.$refs.filterRow.like,
        headers: this.headers,
        options: this.options,
        filters: {
          ...this.filters,
          "carrera.codigo": this.obtenerCoordinaciones(),
        },
        expand: this.expand.toString(),
        search: this.search.tag,
      });
      this.loading.status = false;
    },
    async abrirDocentes(item) {
      this.id = item.id;
      this.examen = item;
      // await this.$refs.docentes.reload();
      this.$refs.docentes.dialogDocentes = true;
    },
  },
  destroyed() {
    this.clearFilters();
  },
  created() {
    this.getCarreras();
    this.getUCurricularCarreras({
      options: { itemsPerPage: -1 },
    });
  },
};
</script>

<style scoped></style>
