<template>
  <div>
    <v-dialog v-model="check" max-width="600px">
      <v-card color="info" dark>
        <v-card-title><h3>Actualice sus Datos</h3></v-card-title>
        <v-card-text>
          Hace mucho tiempo que no actualiza la Información de su Perfil, le
          pedimos que se tome un tiempo para corroborar sus datos. Revisar que
          esten bien escritos Nombre, Apellido, email y telefono. En caso que el
          dni no sea el correcto porfavor escribir un correo a
          <strong>pacho@ifdcsao.edu.ar</strong>
        </v-card-text>
        <v-card-actions class="justify-content-end">
          <div class="text-right">
            <v-btn right text @click="cerrar = true">Cerrar</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogUpdated" max-width="600px">
      <v-card dark color="success">
        <v-card-title class="justify-content-center">
          <h3>Datos Registrados</h3>
        </v-card-title>
        <v-card-text align="center">
          <h5>Muchas Gracias por actualizar sus datos</h5>
        </v-card-text>
        <v-card-actions class="justify-content-end">
          <v-btn @click="dialogUpdated = false" link text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container>
      <div class="d-flex align-center">
        <div class="mr-5">
          <v-avatar size="4rem">
            <v-img :src="avatar"></v-img>
          </v-avatar>
        </div>
        <h3>Mis Datos</h3>
      </div>
      <v-divider class=""></v-divider>
      <!--      <div>-->
      <!--        <v-switch v-model="actualizarDatos" label="Actualizar Datos"></v-switch>-->
      <!--      </div>-->
      <v-form @submit.prevent="actualizar">
        <div class="d-flex">
          <div class="flex-grow-1">
            <v-file-input
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              enctype="multipart/form-data"
              :error-messages="errors.avatar"
              outlined
              label="Subir Imagen de Perfil"
              v-model="user.avatar"
            ></v-file-input>
          </div>
          <div class="ml-3 pt-2">
            <v-btn @click="subirImagen" link icon
              ><v-icon color="primary" x-large>mdi-upload</v-icon></v-btn
            >
          </div>
        </div>

        <v-text-field
          :error-messages="errors.apellido"
          outlined
          label="Ingrese Apellido"
          v-model="user.apellido"
        ></v-text-field>
        <v-text-field
          :error-messages="errors.nombre"
          outlined
          label="Ingrese Nombre"
          v-model="user.nombre"
        ></v-text-field>
        <v-text-field
          :error-messages="errors.email"
          outlined
          label="Ingrese Email"
          v-model="user.email"
        ></v-text-field>
        <v-text-field
          disabled
          outlined
          label="DNI"
          v-model="user.dni"
        ></v-text-field>
        <!--        <small>Teléfono</small>-->
        <v-text-field
          :error-messages="errors.telefono"
          outlined
          label="Ingrese Teléfono"
          v-model="user.telefono"
        ></v-text-field>
        <v-text-field
          :error-messages="errors.direccion"
          outlined
          label="Ingrese Dirección"
          v-model="user.direccion"
        ></v-text-field>
        <v-select
          clearable
          :error-messages="errors.provincia_id"
          outlined
          item-text="nombre"
          item-value="id"
          label="Ingrese Provincia"
          :items="provincias"
          v-model="user.provincia_id"
        ></v-select>
        <v-text-field
          label="Ingrese Localidad"
          outlined
          v-model="user.localidad"
        >
        </v-text-field>

        <v-switch label="Cambiar Password" v-model="cambiarPassword"></v-switch>
        <div v-if="cambiarPassword">
          <small>Password</small>
          <v-text-field
            type="password"
            outlined
            v-model="user.password"
            placeholder="Actualice su password"
          ></v-text-field>
          <small>Repetir Password</small>
          <v-text-field
            :error-messages="errorPassword"
            type="password"
            outlined
            v-model="passwordCheck"
            placeholder="Repita su password"
          ></v-text-field>
        </div>

        <v-card color="grey lighten-5">
          <v-card-title>Espacios Curriculares</v-card-title>
          <v-card-text>
            <v-list color="grey lighten-5">
              <v-list-item v-for="espacio in user.espacios" :key="espacio.id">
                <v-icon class="mr-1">mdi-check</v-icon>
                {{ espacio.nombre }}
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <v-divider></v-divider>

        <div v-show="!verificado">
          <v-checkbox
            class="my-checkbox"
            :error-messages="errorCheckbox"
            dense
            v-model="verificadoCheckbox"
            label="Los Datos estan actualizados"
          ></v-checkbox>
        </div>

        <v-btn type="submbit" outlined color="primary">Guardar</v-btn>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";

export default {
  name: "PerfilDocente",
  data() {
    return {
      // avatar:null,
      dialogUpdated: false,
      cambiarPassword: false,
      passwordCheck: "",
      // errorPassword: null,
      errorCheckbox: null,
      cerrar: false,
      verificado: false,
      verificadoCheckbox: null,
    };
  },
  computed: {
    errorPassword() {
      if (this.user.password == "") {
        return "el password no puede quedar vacio";
      } else if (this.user.password != this.passwordCheck) {
        return "Las contraseñas no coinciden";
      } else {
        return null;
      }
    },

    ...mapState("ProvinciaModule", ["provincias"]),
    ...mapState("LocalidadModule", ["localidades"]),
    ...mapGetters("UserModule", ["errors"]),
    ...mapState("UserModule", ["user", "avatar", "errorArray"]),
    check() {
      return this.user.verificado === null && !this.cerrar;
    },
  },
  methods: {
    ...mapMutations("UserModule", ["setErrors"]),
    ...mapActions("ProvinciaModule", ["getProvincias"]),
    ...mapActions("LocalidadModule", ["getLocalidades"]),
    ...mapActions("UserModule", [
      "updateUser",
      "uploadImagen",
      "getAvatar",
      "getUser",
    ]),
    async actualizar() {
      if (this.cambiarPassword && this.errorPassword) {
        return;
      }
      if (!this.user.verificado && !this.verificadoCheckbox) {
        this.errorCheckbox =
          "Porfavor marque la casilla de que ha actualizado sus datos";
        return;
      }
      await this.updateUser(this.user);
      if (this.user.verificado) {
        this.dialogUpdated = true;
      }
    },
    // checkPassword() {
    //   if (this.user.password != this.passwordCheck) {
    //     this.errorPassword = "Las contraseñas no coinciden";
    //   } else {
    //     this.errorPassword = null;
    //   }
    // },
    async subirImagen() {
      this.setErrors({});
      const formData = new FormData();
      // for(var i  in this.imageFile){
      formData.append("imageFile", this.user.avatar, this.user.avatar.name);
      // }
      formData.append("user", JSON.stringify(this.user));
      // formData.append('imageFile', this.imageFile.file, this.imageFile.name)
      // this.imagen.FK_ID_PROPIEDAD = this.propiedad.ID_PROPIEDAD;
      await this.uploadImagen(formData);
      this.getAvatar(this.user);
    },
  },
  watch: {
    dialogUpdated() {
      if (!this.dialogUpdated) {
        this.$router.go();
      }
    },
    avatarRaw() {
      if (this.avatarRaw) {
        // this.avatar =   URL.createObjectURL(this.avatarRaw);
      }
    },

    verificadoCheckbox() {
      if (this.verificadoCheckbox) {
        this.errorCheckbox = null;
        this.user.verificado = moment(new Date()).format();
      }
    },
  },
  created() {
    this.getUser({
      expand: ["espacios", "roles"],
      filters: { rol: "docente" },
    });
    this.getAvatar(this.user);
    this.getProvincias();
    if (this.user.verificado) {
      this.verificado = true;
    } else {
      this.cambiarPassword = true;
    }
  },
};
</script>

<style>
.my-checkbox .v-label {
  top: 4px;
}
</style>
