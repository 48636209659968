import { httpClient } from "./Api";

const END_POINT = "turnos";

export default {
  vigentes() {
    const params = {
      expand: [].toString(),
    };
    return httpClient.get(END_POINT + "/custom/vigentes", { params });
  },
  all() {
    const params = {
      expand: [].toString(),
      "per-page": 1000,
    };
    return httpClient.get(END_POINT, { params });
  },

  store(data) {
    return httpClient.post(END_POINT, data);
  },

  delete(id) {
    return httpClient.delete(`${END_POINT}/${id}`);
  },

  update(item) {
    return httpClient.put(`${END_POINT}/${item.id}`, item);
  },
  view(id) {
    const params = {
      expand: [].toString(),
    };
    return httpClient.get(`${END_POINT}/${id}`, { params });
  },
  model() {
    return httpClient.get(`${END_POINT}/model`);
  },
};
