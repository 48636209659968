export default {
  alumnoExamen: {},
  alumnoExamens: [],
  errorArray: [],
  fields: {},
  record: {
    nuevo: false,
    actualizado: false,
  },
  pagination: {},
  acta: null,
};
