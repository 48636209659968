import { httpClient } from "@/apis/Api";
// import router from "../router";
// import da from "element-ui/src/locale/lang/da";

/**
 * Created by TheCodeholic on 3/7/2020.
 */
const authService = {
  currentUser: null,

  isLoggedIn() {
    return !!localStorage.getItem("ACCESS_TOKEN");
  },
  getToken() {
    return localStorage.getItem("ACCESS_TOKEN");
  },
  async login(formData, rol = "") {
    try {
      const { status, data } = await httpClient.post(
        "user/login" + rol,
        formData
      );
      if (status === 200) {
        localStorage.setItem("ACCESS_TOKEN", data.token);
        console.log("token");
        console.log(data);
        // this.currentUser=null;
      }
      return {
        success: true,
      };
    } catch (e) {
      console.log(e.response);
      return {
        success: false,
        errors: e.response.data ? e.response.errors : e.response,
      };
    }
  },
  async register(formData) {
    try {
      const { status, data } = await httpClient.post("user/register", formData);
      if (status === 200) {
        console.log(data);
        //localStorage.setItem('ACCESS_TOKEN', data.access_token)
      }
      return {
        success: true,
      };
    } catch (e) {
      console.log(e.response);
      return {
        success: false,
        errors: e.response.data.errors,
      };
    }
  },
  logout() {
    localStorage.removeItem("ACCESS_TOKEN");
  },
  async getUser() {
    // console.log(this.currentUser);
    try {
      if (!this.currentUser) {
        const { status, data } = await httpClient.get("/user/data");
        if (status === 200) {
          this.currentUser = data;
          // console.log(this.currentUser);
        }
      }
    } catch (e) {
      return null;
    }

    return this.currentUser;
  },
  async getUsers() {
    try {
      if (this.isLoggedIn()) {
        const { status, data } = await httpClient.get("/user/all-data");
        if (status === 200) {
          this.users = data;
        }
      }
    } catch (e) {
      return null;
    }

    return this.users;
  },
};

export default authService;
