<template>
  <div>
    <!--    <docente-materia-form ref="form" @close="cerrar"></docente-materia-form>-->

    <v-card>
      <v-card-title> Examenes Finales </v-card-title>
      <v-card-text>
        <!--        <v-switch v-model="swi" label="Vigentes"></v-switch>-->
        <v-divider></v-divider>
        <v-row v-for="(porCarrera, codigo) in porCarreras" :key="codigo">
          <v-col>
            <div class="title teal text-light p-3">
              {{ porCarrera.carrera.nombre }}
            </div>
            <v-row v-for="(materias, anio) in porCarrera.anios" :key="anio">
              <v-col>
                <span class="title">Exámenes Finales de {{ anio }}° Año</span>
                <v-data-iterator :items="materias">
                  <template v-slot:default="{ items }">
                    <v-row>
                      <v-col
                        v-for="item in items"
                        cols="12"
                        md="6"
                        lg="4"
                        :key="item.id"
                      >
                        <v-card dark color="primary">
                          <v-card-title>
                            <div>
                              <div>
                                {{ item.espacio.nombre }}
                              </div>

                              <!--                              <div class="subtitle-1">-->
                              <!--                                Año Lectivo: {{ item.anio_lectivo }}-->
                              <!--                              </div>-->
                              <!--                              <div class="subtitle-1">-->
                              <!--                                Comisión: {{ item.comision }}-->
                              <!--                              </div>-->
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <div class="subtitle-2">
                              {{ item.examen.fechaFormateada }}
                            </div>
                          </v-card-text>
                          <v-card-actions>
                            <div class="w-100">
                              <v-btn block @click="verAlumnos(item)"
                                >Alumnos</v-btn
                              >
                              <v-btn
                                :href="planillaDigital(item.examen_id)"
                                link
                                class="m-1"
                                block
                                color="success"
                                >Planilla Digital Examén</v-btn
                              >
                            </div>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
// import DocenteMateriaForm from "@/flavor/docente/views/materias/DocenteMateriaForm";

export default {
  name: "DocenteExamenesList",
  // components: { DocenteMateriaForm },
  data() {
    return {
      carrera_id: null,
      swi: true,
    };
  },
  computed: {
    // ...mapGetters("NewDocenteEspacio",["porCarreras"]),
    ...mapState("DocenteExamenModule", ["docenteExamens"]),
    ...mapState("UserModule", ["user"]),
    ...mapState("CarreraModule", ["carreras"]),

    vigentes() {
      return this.docenteExamens.filter((el) => !this.swi || el.vigente);
    },

    porCarreras() {
      const carreras = this.vigentes.reduce((carreras, item) => {
        console.log(item);
        let tag = item.espacio.anio;
        let tag2 = item.carrera.codigo;

        carreras[tag2] = carreras[tag2] || {};
        carreras[tag2].carrera = item.carrera;
        carreras[tag2].anios = carreras[tag2].anios || {};

        carreras[tag2].anios[tag] = carreras[tag2].anios[tag] || [];

        carreras[tag2].anios[tag].push(item);
        return carreras;
      }, {});
      return carreras;
    },
  },
  watch: {
    carrera_id() {
      this.reload();
    },
  },
  methods: {
    ...mapActions("DocenteExamenModule", [
      "getDocenteExamens",
      // "deleteDocenteCursado",
    ]),
    ...mapActions("CarreraModule", ["getCarreras"]),

    planillaDigital(id) {
      return "/planilla-digital-examen/" + id;
    },

    reload() {
      this.getDocenteExamens({
        options: {
          // joins: ["carrera"],
          sortBy: ["id"],
          sortDesc: [false],
        },
        filters: { docente_id: this.user.id, carrera_id: this.carrera_id },
        expand: ["espacio", "carrera", "examen", "espacio"].toString(),
      });
    },
    cerrar() {
      this.reload();
    },
    abrirForm() {
      this.$refs.form.dialog = true;
    },
    verAlumnos(item) {
      this.$router.push("/docente/alumno-examen/" + item.examen_id);
    },
  },
  created() {
    this.reload();
    this.getCarreras();
  },
};
</script>

<style scoped></style>
