<template>
  <v-card>
    <v-card-title>
      <div>Datos de Autoridades</div>
      <v-btn link icon absolute top right @click="$emit('close')"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text>
      <!--      <v-autocomplete-->
      <!--        :items="users"-->
      <!--        label="Ingrese Nombre Presidente"-->
      <!--        v-model="examen.presidente"-->
      <!--        outlined-->
      <!--        item-text="fullname"-->
      <!--        item-value="fullname"-->
      <!--      ></v-autocomplete>-->
      <!--      <v-autocomplete-->
      <!--        :items="users"-->
      <!--        label="Ingrese Nombre Vocal 1"-->
      <!--        v-model="examen.vocal1"-->
      <!--        outlined-->
      <!--        item-text="fullname"-->
      <!--        item-value="fullname"-->
      <!--      ></v-autocomplete>-->
      <!--      <v-autocomplete-->
      <!--        :items="users"-->
      <!--        label="Ingrese Nombre Vocal 2"-->
      <!--        v-model="examen.vocal2"-->
      <!--        outlined-->
      <!--        item-text="fullname"-->
      <!--        item-value="fullname"-->
      <!--      ></v-autocomplete>-->
      <div
        class="row"
        v-for="(autoridad, field) in examen.autoridades"
        :key="field"
      >
        <div class="col col-md-8">
          <v-autocomplete
            :items="autoridad.rol == 2 ? estudiantes : docentes"
            :label="`Ingrese Nombre Autoridad`"
            v-model="autoridad.docente"
            outlined
            item-text="fullname"
            item-value="id"
          >
          </v-autocomplete>
        </div>
        <div class="col col-md-4">
          <v-select
            outlined
            label="Rol"
            v-model="autoridad.rol"
            :items="[
              { label: 'presidente', value: 0 },
              { label: 'vocal', value: 1 },
              { label: 'veedor', value: 2 },
            ]"
            item-text="label"
            item-value="value"
          ></v-select>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="agregarAutoridad">Agregar Autoridad</v-btn>
      <v-btn color="primary" @click="$emit('completado')">Generar Acta</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close')">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DatosAuxiliaresExamen",
  computed: {
    ...mapState("ExamenModule", ["examen"]),
    ...mapState("NewUserModule", ["users"]),

    estudiantes() {
      return this.users.filter((el) => el.roles.includes("alumno"));
    },

    docentes() {
      return this.users.filter((el) => el.roles.includes("docente"));
    },
  },
  methods: {
    ...mapActions("NewUserModule", ["getUsersPorRoles"]),
    agregarAutoridad() {
      this.examen.autoridades.push({});
    },
  },
  created() {
    this.getUsersPorRoles({ roles: ["docente", "alumno"] });
  },
};
</script>

<style scoped></style>
