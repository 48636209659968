<template>
  <notas-examen-a-b-m :headers="headers" :examen_id="examen_id" :only="only">
  </notas-examen-a-b-m>
</template>

<script>
import NotasExamenABM from "@/flavor/docente/views/Examenes/NotasExamenABM";

export default {
  props: {
    examen_id: [String, Number],
  },
  name: "NotasExamens",
  components: { NotasExamenABM },
  data() {
    return {
      only: ["observaciones"],
      headers: [
        { text: "Id", value: "id" },
        { text: "Apellido", value: "alumno.apellido" },
        { text: "Nombre", value: "alumno.nombre" },
        { text: "Email", value: "alumno.email" },
        { text: "Oral", value: "nota_oral" },
        { text: "Escrito", value: "nota_escrito" },
        { text: "Final", value: "nota_final" },
        { text: "Ausente", value: "ausente" },
        { text: "Observaciones", value: "actions" },
      ],
    };
  },
};
</script>

<style scoped></style>
