<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="filename"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <!-- PDF Content Here -->

        <v-container class="pdfpage" v-for="(dataitems, i) in pages" :key="i">
          <div class="d-flex justify-content-between align-content-center pr-3">
            <img :src="require('@/assets/logoifdc.png')" height="30" />
            <span>Planilla de Cursado</span>
          </div>
          <div class="d-flex">
            <v-col class="p-0">
              <div>
                <label>Carrera: </label
                ><span
                  ><strong>{{ cursado.carrera.nombre }}</strong></span
                >
              </div>
            </v-col>
            <v-col class="p-0">
              <div>
                <label>Unidad Curricular: </label
                ><span
                  ><strong>{{ cursado.espacio.nombre }}</strong></span
                >
              </div>
            </v-col>
          </div>
          <div class="d-flex">
            <v-col class="p-0">
              <div>
                <label>Año: </label
                ><span
                  ><strong>{{ cursado.espacio.anio }}</strong></span
                >
              </div>
            </v-col>
            <!--            <v-col class="p-0">-->
            <!--              <div><label>Año:</label><span>{{cursado.espacio.anio}}</span></div>-->
            <!--            </v-col>-->
            <v-col class="p-0">
              <div>
                <label>Profesores:</label>
                <!--              <div class="d-flex">-->
                <strong>
                  <span v-for="docente in cursado.docentes" :key="docente.id">
                    {{ docente.apellido }} {{ docente.nombre }},
                  </span>
                </strong>
              </div>
              <!--              </div>-->
            </v-col>
          </div>
          <div class="d-flex">
            <v-col class="p-0">
              <div>
                <label>Fecha Inicio:</label
                ><span
                  ><strong>{{ cursado.fecha_inicio }}</strong></span
                >
              </div>
            </v-col>
            <v-col class="p-0">
              <div>
                <label>Fecha de Finalización:</label
                ><span
                  ><strong>{{ cursado.fecha_fin }}</strong></span
                >
              </div>
            </v-col>
          </div>
          <v-data-table
            class="pdftable"
            :page="i + 1"
            :items-per-page="chunk"
            align="center"
            :items="items"
            :headers="headers"
          >
            <template v-slot:item.tp1="{ item }">
              {{ tps[item.tp1] }}
              <!--        <v-switch :disabled="!disableNotas" label="A" v-model="item.tp1"></v-switch>-->
              <!--              <v-select @change="updateAlumnoCursado(item)" :disabled="!disableNotas" :items="[{value:0,text:'Desaprobado'},{value:1,text:'Aprobado'}]" v-model="item.tp1" item-value="value" item-text="text" ></v-select>-->
            </template>
            <template v-slot:item.tp2="{ item }">
              {{ tps[item.tp2] }}
            </template>
            <template v-slot:item.tp3="{ item }">
              {{ tps[item.tp3] }}
            </template>
            <template v-slot:item.tp4="{ item }">
              {{ tps[item.tp4] }}
            </template>

            <template v-slot:item.nota_alumno="{ item }">
              <strong>{{ item.nota_alumno }}</strong>
            </template>

            <template v-slot:item.cursado_aprobado="{ item }">
              <v-icon v-if="item.cursado_aprobado" color="success" class="mr-2">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="error" class="mr-2">
                mdi-alert-circle
              </v-icon>
            </template>
            <template v-slot:item.observaciones="{ item }">
              <td class="observaciones">{{ abreviar(item.observaciones) }}</td>
            </template>
          </v-data-table>
          <div class="html2pdf__page-break" />
        </v-container>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  props: {
    cursado: Object,
    items: Array,
    // headers: Array,
  },
  name: "AlumnoCursadoPDF",
  data() {
    return {
      tps: ["Desaprobado", "Aprobado"],
      headers: [
        // {text:'Id',value:'id'},
        // { text: "Estado", value: "estadoNombre" },
        { text: "Apellido", value: "alumno.apellido" },
        { text: "Nombre", value: "alumno.nombre" },
        // { text: "Email", value: "alumno.email" },
        { text: "TP 1 ", value: "tp1" },
        { text: "TP 2 ", value: "tp2" },
        { text: "TP 3 ", value: "tp3" },
        { text: "TP 4 ", value: "tp4" },
        // {text: 'TP 1', value: 'tp1'},
        // {text: 'Cursado Aprobado', value: 'cursado_aprobado'},
        { text: "Nota", value: "nota_alumno" },
        { text: "Cursado Aprobado", value: "cursado_aprobado" },
        { text: "Abandono Fecha", value: "fecha_abandono" },
        // { text: "Observaciones", value: "observaciones" },
        // {text: 'Cursado Aprobado', value: 'cursado_aprobado'},
        // {text:'Acciones',value:'actions'},
      ],
    };
  },
  computed: {
    filename() {
      return (
        this.cursado.espacio.nombre +
        "-" +
        this.cursado.anio_lectivo +
        "-comision" +
        this.cursado.comision
      );
    },
    chunk() {
      return 20;
    },
    pages() {
      const myArray = this.items.slice(0, this.items.length);
      let results = [];

      while (myArray.length) {
        results.push(myArray.splice(0, this.chunk));
      }

      return results;
    },
  },
  methods: {
    /*
        Generate Report using refs and calling the
        refs function generatePdf()
    */
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    abreviar(item) {
      return item;
    },
    onProgress() {},
    hasStartedGeneration() {},
    hasGenerated() {},
  },

  components: {
    VueHtml2pdf,
  },
};
</script>

<style>
.pdftable tr,
.pdftable td,
.pdftable th {
  font-size: 11px !important;
  height: 24px !important;
}

.pdfpage label,
.pdfpage span {
  font-size: 11px !important;
}

.observaciones {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}
</style>
