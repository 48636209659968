const getters = {
  errors(state) {
    var merrors = {};
    for (var i in state.errorArray) {
      merrors[state.errorArray[i].field] = state.errorArray[i].message;
    }
    return merrors;
  },

  docentesAgregados(state) {
    return state.cursado.docentes;
  },
};

export default getters;
