<template>
  <div>
    <v-card>
      <v-card-title>ProgramaExamens</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              outlined
              dense
              hide-details
              clearable
              @change="reload"
              v-model="carrera_id"
              :items="carreras"
              item-value="id"
              item-text="nombre"
              placeholder="Ingrese Carrera"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              placeholder="Ingrese Espacio Curricular"
              clearable
              hide-details
              outlined
              dense
              :items="uCurricularCarreras"
              v-model="filters['materia_id']"
              item-value="id"
              item-text="searchTag"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <!--        <v-row>-->
        <!--          <v-col>-->
        <!--            <v-text-field label="Buscar por campo"  v-model="search.tag" clearable @click:clear="reload"></v-text-field>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
        <v-data-iterator
          :items-per-page="-1"
          :hidden="!filters['materia_id']"
          v-model="selectedItems"
          show-select
          :loading="loading.status"
          :items="filteredExamenes"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:default="{ items }">
            <v-list>
              <v-subheader>Programas de Examen</v-subheader>
              <v-list-item-group color="primary">
                <v-list-item
                  link
                  :href="obtenerLink(item)"
                  target="_blank"
                  v-for="(item, i) in items"
                  :key="i"
                >
                  <v-list-item-icon>
                    <v-icon
                      x-large
                      :color="item.condicion === 'EXAMEN' ? 'success' : 'error'"
                      v-text="'mdi-file'"
                    ></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <div>{{ item.materia.searchTag }}</div>
                      <div>{{ menu[item.condicion] }}</div>
                      <div class="title" v-if="item.periodoExamen">
                        {{ item.periodoExamen.nombre }}
                      </div>
                      <div class="title" v-else>
                        {{ "Año: " + item.anio }}
                        {{ "cuatrimestre: " + item.cuatrimestre }}
                      </div>
                      <div :hidden="!item.descripcion">
                        {{ item.descripcion }}
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </template>
        </v-data-iterator>

        <!--        <v-data-table-->
        <!--            v-model="selectedItems"-->
        <!--            show-select-->
        <!--            :loading="loading.status"-->
        <!--            :items="programaExamens"-->
        <!--            :headers="headers"-->
        <!--            :server-items-length="pagination.totalCount"-->
        <!--            :options.sync="options"-->
        <!--        >-->
        <!--          <template v-slot:body.prepend>-->
        <!--            <filter-row-->
        <!--                ref="filterRow"-->
        <!--                :headers="headers"-->
        <!--                @reload="reload"-->
        <!--            >-->
        <!--              <template v-slot:materia.nombre="{}">-->
        <!--                <v-autocomplete-->
        <!--                    clearable-->
        <!--                    hide-details-->
        <!--                    outlined-->
        <!--                    dense-->
        <!--                    multiple-->
        <!--                    :items="uCurricularCarreras"-->
        <!--                    v-model="filters['materia_id']"-->
        <!--                    item-value="id"-->
        <!--                    item-text="searchTag"-->
        <!--                ></v-autocomplete>-->
        <!--              </template>-->
        <!--              <template v-slot:carrera.codigo="{}">-->
        <!--                <v-select-->
        <!--                    outlined-->
        <!--                    dense-->
        <!--                    hide-details-->
        <!--                    clearable-->
        <!--                    @change="reload"-->
        <!--                    v-model="filters['carrera.codigo']"-->
        <!--                    :items="codigos"-->
        <!--                ></v-select>-->
        <!--              </template>-->
        <!--            </filter-row>-->
        <!--          </template>-->

        <!--          <template v-slot:item.archivo="{ item }">-->
        <!--            <v-btn color="success" link :href="item.archivo_examen">Descargar</v-btn>-->
        <!--          </template>-->

        <!--          <template v-slot:item.actions="{ item }">-->
        <!--            <v-icon color="info" class="mr-2" @click="editItem(item)">-->
        <!--              mdi-pencil-->
        <!--            </v-icon>-->
        <!--            <v-icon color="info" @click="deleteItem(item.id)"> mdi-delete </v-icon>-->
        <!--          </template>-->

        <!--        </v-data-table>-->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import FilterRow from "@/viewsNew/utils/FilterRow";
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "ProgramaExamenAlumno",
  // components: {FilterRow},
  data() {
    return {
      menu: {
        CURSADO: "PROGRAMA DE CURSADO",
        EXAMEN: "PROGRAMA DE EXAMEN REGULAR",
      },
      result: null,
      carrera_id: null,
      filters: {},
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      options: {
        itemsPerPage: -1,
        joins: ["periodoExamen", "carrera"],
      },
      expand: ["carrera", "materia", "periodoExamen"],
    };
  },
  computed: {
    ...mapGetters("CarreraModule", ["codigos"]),
    ...mapState("CarreraModule", ["carreras"]),
    ...mapState("NewUCurricularCarreraModule", ["uCurricularCarreras"]),
    ...mapState("ProgramaExamenModule", ["programaExamens", "pagination"]),
    ...mapState(["search", "loading", "dialogs"]),

    filteredExamenes() {
      return this.programaExamens.filter((el) => el.estado === "PUBLICADO");
    },

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        // {text:'Id',value:'id',filterable:true},
        { text: "Carrera", value: "carrera.codigo", filterable: false },
        {
          text: "Materia",
          value: "materia.nombre",
          filterable: false,
          table: "u_curricular_carrera.nombre",
        },
        { text: "periodo", value: "periodoExamen.nombre", filterable: true },
        { text: "Archivo", value: "archivo", filterable: true },

        // {text:'Acciones',value:'actions',filterable:false,nosearch:true},
      ];
    },
  },
  watch: {
    carrera_id() {
      this.getMaterias();
    },
    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("NewUCurricularCarreraModule", ["getUCurricularCarreras"]),
    ...mapActions("ProgramaExamenModule", [
      "getProgramaExamens",
      "deleteProgramaExamen",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    obtenerLink(item) {
      if (item.archivo_examen) {
        return item.archivo_examen;
      }
      const API_ENDPOINT =
        process.env.NODE_ENV === "development"
          ? process.env.VUE_APP_HOST_DESARROLLO
          : process.env.VUE_APP_HOST_PRODUCCION;
      const url = `${API_ENDPOINT}/v1/api/programa-examen/custom/download?id=${item.id}`;
      return url;
    },

    getMaterias() {
      this.getUCurricularCarreras({
        filters: { carrera_id: this.carrera_id },
      });
    },

    newItem() {
      this.$refs.form.model = { carrera: {} };
      this.$refs.form.dialog = true;
    },

    cerrar() {
      this.$refs.form.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.$refs.form.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteProgramaExamen(id);
      }
    },

    async reload() {
      this.loading.status = true;
      await this.getProgramaExamens({
        // like: this.$refs.filterRow.like,
        like: this.filters,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.clearFilters();
  },
  created() {
    this.getCarreras();
    this.getUCurricularCarreras({
      options: { itemsPerPage: -1 },
    });
  },
};
</script>

<style scoped></style>
