import AlumnoCarrera from "@/apis/AlumnoCarrera";

const actions = {
  async sendMailTurno({ commit }, id) {
    try {
      const { status } = await AlumnoCarrera.sendMailTurno(id);
      if (status === 200) {
        // console.log(data);
        // commit("setModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },

  async sendMailPreinscripcion({ commit }, id) {
    try {
      const { status } = await AlumnoCarrera.sendMailPreinscripcion(id);
      if (status === 200) {
        // console.log(data);
        // commit("setModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  async sendMailConfirmacion({ commit }, id) {
    try {
      const { status } = await AlumnoCarrera.sendMailConfirmacion(id);
      if (status === 200) {
        // console.log(data);
        // commit("setModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },

  async deleteDocumentacion({ commit }, bundle) {
    try {
      const { status } = await AlumnoCarrera.deleteDocumentacion(bundle);
      if (status === 200) {
        // console.log(data);
      }
    } catch (e) {
      // console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFrenteDni({ commit }, bundle) {
    try {
      bundle.field = "dni_frente";
      const { status, data } = await AlumnoCarrera.getDocumentacion(bundle);
      if (status === 200) {
        bundle.type = data.type;
        var reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          bundle.data = imageDataUrl;
          commit("setDniFrente", bundle);
        };
      }
    } catch (e) {
      // console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async getDniDorso({ commit }, bundle) {
    try {
      bundle.field = "dni_dorso";
      const { status, data } = await AlumnoCarrera.getDocumentacion(bundle);
      if (status === 200) {
        bundle.type = data.type;
        var reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          bundle.data = imageDataUrl;
          commit("setDniDorso", bundle);
        };
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  async getCuil({ commit }, bundle) {
    try {
      bundle.field = "cuil";
      const { status, data } = await AlumnoCarrera.getDocumentacion(bundle);
      if (status === 200) {
        // console.log(data);
        bundle.type = data.type;
        var reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          bundle.data = imageDataUrl;
          commit("setCuil", bundle);
        };
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  async getFormularioInscripcion({ commit }, bundle) {
    try {
      bundle.field = "formulario_inscripcion";
      const { status, data } = await AlumnoCarrera.getDocumentacion(bundle);
      if (status === 200) {
        bundle.type = data.type;
        var reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          bundle.data = imageDataUrl;
          commit("setFormularioInscripcion", bundle);
        };
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  async getFormularioMedico({ commit }, bundle) {
    try {
      bundle.field = "formulario_medico";
      const { status, data } = await AlumnoCarrera.getDocumentacion(bundle);
      if (status === 200) {
        bundle.type = data.type;
        var reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          bundle.data = imageDataUrl;
          commit("setFormularioMedico", bundle);
        };
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },

  async uploadDocumentacion({ commit }, item) {
    try {
      const { status } = await AlumnoCarrera.uploadDocumentacion(item);
      if (status === 200) {
        // console.log(data);
        // commit("setModel", data);
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },

  showDialog({ commit }) {
    commit("showDialog");
  },

  hideDialog({ commit }) {
    commit("hideDialog");
  },

  editAlumnoCarrera({ commit }, model) {
    commit("setModel", model);
  },

  async addAlumnoCarrera({ commit }, item) {
    try {
      const { status, data } = await AlumnoCarrera.store(item);
      if (status === 201) {
        commit("addModel", data);
        commit("setModel", data);
        return data;
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async deleteAlumnoCarrera({ commit }, id) {
    try {
      const { status } = await AlumnoCarrera.delete(id);
      if (status === 204) {
        commit("delModel", id);
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
  async updateAlumnoCarrera({ commit }, model) {
    try {
      const { status, data } = await AlumnoCarrera.update(model);
      if (status === 200) {
        // console.log(data);
        return data;
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getCarrerasPorAlumno({ commit }, bundle) {
    try {
      const { status, data, headers } = await AlumnoCarrera.porAlumno(bundle);
      if (status === 200) {
        commit("setModels", { models: data, headers: headers });
      }
    } catch (e) {
      // console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getAlumnoCarreras({ commit }, bundle) {
    try {
      const { status, data, headers } = await AlumnoCarrera.all(bundle);
      if (status === 200) {
        commit("setModels", { models: data, headers: headers });
      }
    } catch (e) {
      // console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getAlumnoCarrera({ commit }, bundle) {
    try {
      const { status, data } = await AlumnoCarrera.view(bundle);
      if (status === 200) {
        commit("setModel", data);
      }
    } catch (e) {
      // console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getAlumnosPorCarrera({ commit }, bundle) {
    try {
      const { status, data, headers } = await AlumnoCarrera.porCarrera(bundle);
      if (status === 200) {
        commit("setModels", { models: data, headers: headers });
      }
    } catch (e) {
      // console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },

  async getFields({ commit }) {
    try {
      const { status, data } = await AlumnoCarrera.model();
      if (status === 200) {
        commit("setFields", data);
      }
    } catch (e) {
      // console.log(e);
      commit("setErrors", e.response ? e.response.data : e);
    }
  },
};

export default actions;
