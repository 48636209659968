const mutations = {
  clearDocumentacion(state) {
    state.documentacion = {
      dni_frente: {},
      dni_dorso: {},
      cuil: {},
      formulario_inscripcion: {},
      formulario_medico: {},
    };
  },

  setDniFrente(state, bundle) {
    state.documentacion.dni_frente = { data: bundle.data, type: bundle.type };
    state.key++;
  },
  setDniDorso(state, bundle) {
    state.documentacion.dni_dorso = { data: bundle.data, type: bundle.type };
    state.key++;
  },
  setCuil(state, bundle) {
    state.documentacion.cuil = { data: bundle.data, type: bundle.type };
    state.key++;
  },
  setFormularioInscripcion(state, bundle) {
    state.documentacion.formulario_inscripcion = {
      data: bundle.data,
      type: bundle.type,
    };
    state.key++;
  },
  setFormularioMedico(state, bundle) {
    state.documentacion.formulario_medico = {
      data: bundle.data,
      type: bundle.type,
    };
    state.key++;
  },

  showDialog(state) {
    state.dialog = true;
  },

  hideDialog(state) {
    state.dialog = false;
  },

  setModel(state, model) {
    state.errorArray = [];
    state.alumnoCarrera = model;
  },

  setModels(state, data) {
    state.alumnoCarreras = data.models;
    state.pagination = data.headers;
    state.pagination.totalCount = +data.headers["x-pagination-total-count"];
  },

  addModel(state, model) {
    state.nuevo = true;
    state.alumnoCarreras.unshift(model);
  },

  delModel(state, id) {
    const removeIndex = state.alumnoCarreras
      .map(function (item) {
        return item.id;
      })
      .indexOf(id);
    state.alumnoCarreras.splice(removeIndex, 1);
  },

  setErrors(state, errors) {
    state.errorArray = errors;
  },

  setFields(state, data) {
    state.fields = data.fields;
    state.emptyModel = data.model;
  },
};

export default mutations;
