<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({}),
  created() {
    // Setting Language in the HTML document
    document.documentElement.setAttribute("lang", "es");
  },
};
</script>

<style></style>
