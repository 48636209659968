<template>
  <notas-cursado-a-b-m :headers="headers" :cursada_id="cursada_id" :only="only">
    <template v-slot:item.email_enviado="{ item }">
      <v-chip :color="item.mail_enviado > 0 ? 'success' : ''">{{
        item.mail_enviado
      }}</v-chip>
    </template>
  </notas-cursado-a-b-m>
</template>

<script>
import NotasCursadoABM from "@/flavor/docente/views/Cursada/NotasCursadoABM";

export default {
  name: "NotasCursados",
  props: {
    cursada_id: [String, Number],
  },
  components: { NotasCursadoABM },
  data() {
    return {
      only: ["fecha_abandono", "observaciones"],
      headers: [
        // {text:'Id',value:'id'},
        // {text: 'Estado', value: 'estadoNombre'},
        { text: "Apellido", value: "alumno.apellido" },
        { text: "Nombre", value: "alumno.nombre" },
        { text: "Email", value: "alumno.email" },
        { text: "TP 1 Aprobado", value: "tp1" },
        { text: "TP 2 Aprobado", value: "tp2" },
        { text: "TP 3 Aprobado", value: "tp3" },
        { text: "TP 4 Aprobado", value: "tp4" },
        // {text: 'TP 1', value: 'tp1'},
        // {text: 'Cursado Aprobado', value: 'cursado_aprobado'},
        { text: "Nota", value: "nota_alumno" },
        { text: "Cursado Aprobado", value: "cursado_aprobado" },

        { text: "Abandono", value: "fecha_abandono" },

        { text: "Observaciones", value: "observaciones" },
        { text: "Email Enviado", value: "email_enviado" },
      ],
    };
  },
};
</script>

<style scoped></style>
