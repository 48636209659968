

const mutations = {
  setModels(state, data) {
    state.turnos = data.items
    state.pagination = data._meta
  },

  setModel(state, data) {
    state.turno = data;
  },

  addModel(state, model) {
    state.record.nuevo = true;
    state.turnos.unshift(model);
  },

  updateModel(state, model) {
    state.model = model
    state.record.actualizado = true;
  },

  delModel(state, id) {
    const removeIndex = state.turnos
        .map(function (item) {
          return item.id;
        })
        .indexOf(id);
    state.turnos.splice(removeIndex, 1);
  },

  setErrors(state, errors) {
    state.errorArray = errors;
  },

  setFields(state, data) {
    state.fields = data.fields;
    state.emptyModel = data.model;
  },

};
export default mutations;
