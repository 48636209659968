const getters = {
  errors(state) {
    var merrors = {};
    for (var i in state.errorArray) {
      merrors[state.errorArray[i].field] = state.errorArray[i].message;
    }
    return merrors;
  },
  porCarrera(state) {
    const carreras = state.alumnoExamens.reduce((carreras, item) => {
      let tag = item.materia.anio + "° AÑO";
      tag = item.materia.anio;
      let tag2 = item.carrera.codigo;

      carreras[tag2] = carreras[tag2] || {};
      carreras[tag2].carrera = item.carrera;
      carreras[tag2].anios = carreras[tag2].anios || {};

      carreras[tag2].anios[tag] = carreras[tag2].anios[tag] || [];

      carreras[tag2].anios[tag].push(item);
      return carreras;
    }, {});
    return carreras;
  },
};

export default getters;
