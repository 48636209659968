import Vue from "vue";
import { httpClient } from "@/apis/Api";

const END_POINT = "alumno-examen";

export default {
  misExamenes(bundle) {
    const params = {
      expand: bundle.expand,
    };
    return Vue.prototype.$axios.get(END_POINT + "/custom/poralumno", {
      params,
    });
  },

  customQuery() {
    const params = {};
    return Vue.prototype.$axios.get(END_POINT + "/custom/query", { params });
  },

  actaExamen(examen) {
    const params = {
      // examen: examen,
    };
    return httpClient.post(END_POINT + "/custom/actaexamen", examen, {
      params: params,
      responseType: "blob",
    });
  },

  sendMailInscripcion(id) {
    const params = {
      id: id,
    };
    return httpClient.get(END_POINT + "/custom/sendmailinscripcion", {
      params: params,
    });
  },
};
