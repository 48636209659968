const mutations = {
  setModels(state, data) {
    state.horarioCursados = data.items;
    state.pagination = data._meta;
  },

  setModel(state, data) {
    state.horarioCursado = data;
  },

  addModel(state, model) {
    state.record.nuevo = true;
    state.horarioCursados.unshift(model);
  },

  updateModel(state, model) {
    const index = state.horarioCursados
      .map(function (item) {
        return item.id;
      })
      .indexOf(model.id);
    state.horarioCursados.splice(index, 1, model);
    state.record.actualizado = true;
  },

  delModel(state, id) {
    const removeIndex = state.horarioCursados
      .map(function (item) {
        return item.id;
      })
      .indexOf(id);
    state.horarioCursados.splice(removeIndex, 1);
  },

  setErrors(state, errors) {
    state.errorArray = errors;
  },

  setFields(state, data) {
    state.fields = data.fields;
    state.emptyModel = data.model;
  },
};
export default mutations;
