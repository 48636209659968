export default {
  alumnoCursado: {},
  alumnoCursados: [],
  errorArray: [],
  fields: {},
  record: {
    nuevo: false,
    actualizado: false,
  },
  pagination: {},
};
