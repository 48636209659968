export default {
  correlativas: {},
  correlativass: [],
  errorArray: [],
  fields: {},
  record: {
    nuevo: false,
    actualizado: false,
  },
  pagination: {},
};
