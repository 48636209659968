export default {
  uCurricularCarrera: {},
  uCurricularCarreras: [],
  errorArray: [],
  fields: {},
  record: {
    nuevo: false,
    actualizado: false,
  },
  pagination: {},
};
